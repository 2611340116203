export default {
  SET_COUNTRIES(state, array) {
    state.countries = array;
  },
  SET_CURRENCIES(state, array) {
    state.currencies = array;
  },
  SET_CATEGORIES(state, array) {
    state.categories = array;
  },
  SET_STATES(state, array) {
    state.states = array;
  },
  SET_CITIES(state, array) {
    state.cities = array;
  },
  SET_CITY(state, array) {
    state.city = array;
  },
  SET_BANKS(state, array) {
    state.banks = array;
  },
  SET_IS_OPEN(state, value) {
    state.is_open = value;
  },
  SET_VERIFICATION_TYPES(state, array) {
    state.verification_types = array;
  },
  SET_ROLES(state, array) {
    state.roles = array;
  },
  SET_BANKS(state, array) {
    state.banks = array;
  },
  SET_POST_CATEGORIES(state, array) {
    state.post_categories = array;
  },
  SET_STATISTICS(state, array) {
    state.statistics = array;
  }

}
