<template>
  <div class="container-fluid">
    <div class="table pt-2 pb-4 pr-3">
      <div class="table-header">
        <div class="grid">
          <div>
            <small class="text-opacity"
              >{{ paginationData.total }} total groups</small
            >
          </div>
        </div>
        <br />
        <filter-dropdown
          @setFilters="setFilters"
          :params="moreParams"
        ></filter-dropdown>
        <button
          @click="clearFilters"
          :class="`px-3 py-3 bg-red-600 rounded-md text-white font-small tracking-wide hover:bg-red-200`"
        >
          Clear Filters
        </button>
        <router-link
          :to="{ path: `/groups/new` }"
          :class="`ml-4 px-3 py-3 bg-green-600 rounded-md text-white font-small tracking-wide hover:bg-green-200`"
        >
          New Group
        </router-link>
      </div>
      <div style="padding: 10px">
        <span
          class="bg-green-100 border border-green-400 text-green-700 px-1 py-1"
          >Buying Cost: </span
        > <span class="bg-red-100 border border-red-400 text-red-700 px-1 py-1"
          >{{ $route.query.currency ? rates[$route.query.currency].symbol : "₦"
          }}{{
            parseFloat(
              $route.query.currency
                ? buyingCost / rates[$route.query.currency].rate
                : buyingCost
            ).toFixed(2) | numberWithCommas
          }}</span
        > |
        <span
          class="bg-green-100 border border-green-400 text-green-700 px-1 py-1"
          >Potential Turnover:
        </span>
        <span class="bg-red-100 border border-red-400 text-red-700 px-1 py-1"
          >{{ $route.query.currency ? rates[$route.query.currency].symbol : "₦"
          }}{{
            parseFloat(
              $route.query.currency
                ? potentialTurnover / rates[$route.query.currency].rate
                : potentialTurnover
            ).toFixed(2) | numberWithCommas
          }} </span
        > |
        <span
          class="bg-green-100 border border-green-400 text-green-700 px-1 py-1"
          >Current Turnover:
        </span>
        <span class="bg-red-100 border border-red-400 text-red-700 px-1 py-1"
          >{{ $route.query.currency ? rates[$route.query.currency].symbol : "₦"
          }}{{
            parseFloat(
              $route.query.currency
                ? currentTurnover / rates[$route.query.currency].rate
                : currentTurnover
            ).toFixed(2) | numberWithCommas
          }}
        </span>
         |
        <span
          class="bg-green-100 border border-green-400 text-green-700 px-1 py-1"
          >Margin:
        </span>
        <span class="bg-red-100 border border-red-400 text-red-700 px-1 py-1"
          >{{ $route.query.currency ? rates[$route.query.currency].symbol : "₦"
          }}{{
            parseFloat(
              $route.query.currency
                ? margin / rates[$route.query.currency].rate
                : margin
            ).toFixed(2) | numberWithCommas
          }}
        </span>
      </div>
      <div
        class="
          align-middle
          inline-block
          shadow
          sm:rounded-lg
          border-b border-gray-200
        "
      >
        <vuetable
          ref="vuetable"
          :css="table_css.table"
          :api-url="api"
          :append-params="moreParams"
          :http-options="{
            headers: { Authorization: 'Bearer ' + $auth.token() },
          }"
          @vuetable:loaded="loadTable"
          @vuetable:cell-clicked="goto"
          :fields="columns.filter((s) => s.active === true)"
          data-path="data"
          pagination-path="meta"
          :no-data-template="
            rowCount === null ? 'Loading data' : 'No Data Available'
          "
          @vuetable:pagination-data="onPaginationData"
        >
          <template slot="service" slot-scope="props">
            <router-link v-if="props.rowData.service"
              :to="{ path: `/services/${props.rowData.service_id}` }"
              >{{ props.rowData.service.name }}</router-link
            >
          </template>

          <template slot="buying_cost" slot-scope="{ rowData: group }">
            {{
              $route.query.currency ? rates[$route.query.currency].symbol : "₦"
            }}{{
              parseFloat(
                $route.query.currency
                  ? resolveBuyingPrice(group) /
                      rates[$route.query.currency].rate
                  : resolveBuyingPrice(group)
              ).toFixed(2) | numberWithCommas
            }}
          </template>

          <template slot="potential_turnover" slot-scope="{ rowData: group }">
            {{
              $route.query.currency ? rates[$route.query.currency].symbol : "₦"
            }}{{
              parseFloat(
                $route.query.currency
                  ? calculatePotentialTurnOver(group) /
                      rates[$route.query.currency].rate
                  : calculatePotentialTurnOver(group)
              ).toFixed(2) | numberWithCommas
            }}
          </template>

          <template slot="current_turnover" slot-scope="{ rowData: group }">
            {{
              $route.query.currency ? rates[$route.query.currency].symbol : "₦"
            }}{{
              parseFloat(
                $route.query.currency
                  ? group.subscriptions.reduce(
                      (a, b) =>
                        a + b.payments.reduce((c, d) => c + d.amount, 0),
                      0
                    ) / rates[$route.query.currency].rate
                  : group.subscriptions.reduce(
                      (a, b) =>
                        a + b.payments.reduce((c, d) => c + d.amount, 0),
                      0
                    )
              ).toFixed(2) | numberWithCommas
            }}
          </template>

          <template slot="margin" slot-scope="{ rowData: group }">
            {{
              $route.query.currency ? rates[$route.query.currency].symbol : "₦"
            }}{{
              parseFloat(
                $route.query.currency
                  ? getMargin(group) / rates[$route.query.currency].rate
                  : getMargin(group)
              ).toFixed(2) | numberWithCommas
            }}
          </template>

          <template slot="actions" slot-scope="props" class="p-10">
            <div class="dropdown block relative">
              <button
                class="
                  bg-gray-300
                  text-gray-700
                  font-semibold
                  py-2
                  px-4
                  rounded
                  inline-flex
                  items-center
                "
              >
                <span class="mr-1">Actions</span>
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </button>
              <ul class="dropdown-menu hidden text-gray-700 pt-1">
                <li class="">
                  <router-link
                    :class="`rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap`"
                    :to="{ path: `/groups/${props.rowData.id}` }"
                    >View</router-link
                  >
                </li>
                <li class="">
                  <router-link
                    :class="`rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap`"
                    :to="{
                      path: `/subscriptions`,
                      query: { group_id: props.rowData.id },
                    }"
                    >Subscriptions</router-link
                  >
                </li>
              </ul>
            </div>
          </template>
        </vuetable>
        <vuetable-pagination
          v-show="paginationData.total"
          :css="table_css.pagination"
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        ></vuetable-pagination>
        <div class="text-center" v-if="rowCount <= 0 || rowCount === null">
          <slot name="empty"></slot>
        </div>
      </div>
    </div>
    <modal
      v-if="deleteAction"
      :open="deleteAction"
      @performAction="deleteItem"
      :model="model"
      :title="title"
      :description="description"
    ></modal>
  </div>
</template>

<script>
import VuetableCss from "@/helpers/vuetable_styling.js";
import FilterDropdown from "./Filter";
import getPaginationData from "@/helpers/pagination";
import VuetablePagination from "@/components/VueTablePagination";
import Modal from "@/components/Modal";
import { toLocal } from "@/helpers/date";
import { optional } from "@/helpers/global";
import moment from "moment";

export default {
  title: "Groups",
  components: { FilterDropdown, VuetablePagination, Modal },
  data() {
    return {
      title: null,
      description: null,
      model: null,
      deleteAction: false,
      rowCount: null,
      visibleFilter: false,
      table_css: VuetableCss,
      columns: [
        {
          name: "id",
          title: "ID",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:service",
          title: "Service",

          cvisible: true,
          active: true,
        },
        {
          name: "email",
          title: "Email",

          cvisible: true,
          active: true,
        },
        {
          name: "limit",
          title: "Limit",
          cvisible: true,
          active: true,
        },
        {
          name: "subscriptions.length",
          title: "Subscriptions",
          cvisible: true,
          active: true,
          callback: (s) => {
            return s || 0;
          },
        },
        {
          name: "__slot:buying_cost",
          title: "Buying Cost",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:potential_turnover",
          title: "Potential Turnover",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:current_turnover",
          title: "Current turnover",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:margin",
          title: "Margin",
          cvisible: true,
          active: true,
        },
        {
          name: "active",
          title: "Status",
          cvisible: true,
          active: true,
          callback: (s) => {
            let color = s ? "green" : "red";
            return `<span class="bg-${color}-100 border border-${color}-400 text-${color}-700 px-4 py-3 rounded relative">${
              s ? "Active" : "Deactivated"
            }</span>`;
          },
        },
        {
          name: "plan",
          title: "Plan",
          cvisible: true,
          active: true,
          callback: (s) => {
            let color = s === 'shared' ? "green" : s === 'family' ? "red" : s === 'personal' ? 'blue' : 'black';
            return `<span class="bg-${color}-100 border border-${color}-400 text-${color}-700 px-4 py-3 rounded relative">${
              s || 'N/A'
            }</span>`;
          },
        },
        {
          name: "meta.card",
          title: "Card",
          cvisible: true,
          active: true,
        },
        {
          name: "created_at",
          title: "Creation Date",
          cvisible: true,
          active: true,
          sortField: "created_at",
          callback: (s) => {
            return s ? toLocal(s).format("lll") : "N/A";
          },
        },
        {
          name: "__slot:actions",
          title: "Actions",
          active: true,
          cvisible: false,
        },
      ],
      moreParams: {
        per_page: 100,
        service_id: [],
        page: 1,
      },
      paginationData: {},
      api: `${this.$baseApi}/admin/groups`,
      buyingCost: 0,
      potentialTurnover: 0,
      currentTurnover: 0,
      margin: 0,
    };
  },
  methods: {
    toLocal,
    getPaginationData,
    onPaginationData(paginationData) {
      this.paginationData = getPaginationData(paginationData, this.moreParams);
      this.$refs.pagination.setPaginationData(this.paginationData);
    },
    goto(e) {
      this.$router.push({ path: `/groups/${e.id}` });
    },
    loadTable() {
      let d = this.$refs.vuetable.tableData;
      d && this.generateMetrics(d);
      this.rowCount = d && d.length;
    },
    setFilters(data) {
      for (let [key, value] of Object.entries(this.moreParams)) {
        if (typeof value === "undefined") {
          delete this.moreParams[key];
        }

        let data, newData;
        switch (key) {
          case "service":
            data = value ? value.map((s) => s.id) : [];
            this.moreParams[`service_id`] = [
              ...new Set([...this.moreParams[`service_id`], ...data]),
            ];
            // delete this.moreParams[key];
            break;

          case "created_at_from":
          case "created_at_to":
            if (value && value.split("T").length > 1)
              this.moreParams[key] = value;
            break;
          default:
            this.moreParams[key] = value;
            break;
        }
      }

      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    clearFilters() {
      this.moreParams = {
        per_page: 100,
        service_id: [],
        page: 1,
      };
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    onChangePage(page) {
      this.moreParams.page = page;
      this.setFilters();
    },
    refreshTable() {
      this.$refs.vuetable.refresh();
    },
    showVisibleFilter(d) {
      this.visibleFilter = d;
    },
    removeColumnFromTable(data) {
      this.columns.find((s) => s.name === data.name).active = data.val;
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.$refs.vuetable.normalizeFields();
      });
    },
    getMargin(group) {
      let paymentAmount = group.subscriptions.reduce(
        (a, b) => a + b.payments.reduce((c, d) => c + (d.amount - d.fee), 0),
        0
      );
      return paymentAmount
        ? paymentAmount - this.resolveBuyingPrice(group)
        : 0;
    },
    generateMetrics(groups) {
      this.buyingCost = 0;
      this.potentialTurnover = 0;
      this.currentTurnover = 0;
      this.margin = 0;

      for (let group of groups) {
        if (!optional(group, 'service.meta.price')) {
          continue;
        }
        let months = Math.ceil(
          Math.abs(moment(group.created_at).diff(moment(), "months", true))
        );
        let buyingCost = this.resolveBuyingPrice(group);
        this.buyingCost += buyingCost;
        this.potentialTurnover +=
          group.service.price_shared * group.limit * months;
        this.currentTurnover += group.subscriptions.reduce(
          (a, b) => a + b.payments.reduce((c, d) => c + d.amount, 0),
          0
        );
        let paymentAmount = group.subscriptions.reduce(
          (a, b) => a + b.payments.reduce((c, d) => c + (d.amount - d.fee), 0),
          0
        );
        this.margin += paymentAmount ? paymentAmount - buyingCost : 0;
      }
    },
    resolveBuyingPrice(group) {
      let months = Math.ceil(
          Math.abs(moment(group.created_at).diff(moment(), "months", true))
        );
      let pricePerUnit = optional(group, 'service.meta.price')
      let cumulativePrice = pricePerUnit * months;
      if ([152, 132, 102, 51, 41, 31, 21, 11].includes(group.service_id)) {
        cumulativePrice -= pricePerUnit;
      }

      return cumulativePrice;
    },
    calculatePotentialTurnOver(group) {
      let months = Math.ceil(
        Math.abs(moment(group.created_at).diff(moment(), "months", true))
      );
      return optional(group, 'service.price_shared', 0) * group.limit * months;
    },
  },
  computed: {
    rates() {
      return this.$store.getters["global/rates"];
    },
  },
  created() {
    let moreParams = this.moreParams;
    this.moreParams = { ...moreParams, ...this.$route.query };

    this.loaded = true;
  },
};
</script>
