const ignoredTitled = ['headings', 'link', 'image', 'table']
function getTitle (vm) {
  const { title } = vm.$options
  if (title) {
      return typeof title === 'function'
          ? title.call(vm)
          : title
  }
}
export default {
  created () {
      const title = getTitle(this)
      if (title && !ignoredTitled.includes(title)) {
          document.title = `${title} | Admin | ${process.env.VUE_APP_NAME}`
      }
  }
}
