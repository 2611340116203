import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const groups = {
  namespaced: true,
  state: {
    groups : [],
    group : {},
    meta : {}
  },
  actions,
  getters,
  mutations
}
