var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"table pt-2 pb-4 pr-3"},[_c('div',{staticClass:"table-header"},[_c('div',{staticClass:"grid"},[_c('div',[_c('small',{staticClass:"text-opacity"},[_vm._v(_vm._s(_vm.paginationData.total)+" total trails")])])]),_c('br'),_c('trail-filter',{attrs:{"params":_vm.moreParams},on:{"setFilters":_vm.setFilters}}),_c('button',{class:`px-6 py-3 bg-red-600 rounded-md text-white font-medium tracking-wide hover:bg-red-500`,on:{"click":_vm.clearFilters}},[_vm._v(" Clear Filters ")])],1),_c('div',{staticClass:"align-middle inline-block shadow sm:rounded-lg border-b border-gray-200 w-full mt-5 table-tt"},[_c('vuetable',{ref:"vuetable",staticClass:"w-full",attrs:{"css":_vm.table_css.table,"api-url":_vm.api,"append-params":_vm.moreParams,"http-options":{
          headers: { Authorization: 'Bearer ' + _vm.$auth.token() },
        },"fields":_vm.columns.filter((s) => s.active === true),"data-path":"data","pagination-path":"meta","no-data-template":_vm.rowCount === null ? 'Loading data' : 'No Data Available'},on:{"vuetable:loaded":_vm.loadTable,"vuetable:cell-clicked":_vm.goto,"vuetable:pagination-data":_vm.onPaginationData},scopedSlots:_vm._u([{key:"creator",fn:function(props){return [_vm._v(" "+_vm._s(_vm._f("optional")(props.rowData.user,"email", "N/A"))+" ")]}},{key:"user_ref",fn:function(props){return [_c('router-link',{attrs:{"to":{
              path: `/audit-trails`,
              query: { user_ref: `${props.rowData.user_ref}` },
            }}},[_vm._v(_vm._s(props.rowData.user_ref))])]}},{key:"uri",fn:function(props){return [_c('router-link',{attrs:{"to":{
              path: `/audit-trails`,
              query: { uri: `${props.rowData.uri}` },
            }}},[_vm._v(_vm._s(props.rowData.uri))])]}},{key:"actions",fn:function(props){return [_c('div',{staticClass:"dropdown block relative"},[_c('button',{staticClass:"bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center"},[_c('span',{staticClass:"mr-1"},[_vm._v("Actions")]),_c('svg',{staticClass:"fill-current h-4 w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"}})])]),_c('ul',{staticClass:"dropdown-menu hidden text-gray-700 pt-1"},[_c('li',{},[_c('router-link',{class:`rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap`,attrs:{"to":{ path: `/audit-trails/${props.rowData.id}` }}},[_vm._v("View")])],1)])])]}}])}),_c('vuetable-pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.paginationData.total),expression:"paginationData.total"}],ref:"pagination",attrs:{"css":_vm.table_css.pagination},on:{"vuetable-pagination:change-page":_vm.onChangePage}}),(_vm.rowCount <= 0 || _vm.rowCount === null)?_c('div',{staticClass:"text-center"},[_vm._t("empty")],2):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }