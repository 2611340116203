import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css';

import Dashboard from "./views/Dashboard.vue";
import Login from "./views/Auth/Login.vue";
import Profile from "./views/Auth/Profile.vue";

import Error404 from "./views/Errors/404.vue";
import Error403 from "./views/Errors/403.vue"

import AdminIndex from "@/views/Admin/Index.vue";
import AdminShow from "@/views/Admin/Show.vue";

import PaymentIndex from "@/views/Payment/Index.vue";
import PaymentShow from "@/views/Payment/Show.vue";

import AuditTrailIndex from "@/views/AuditTrail/Index.vue";
import AuditTrailShow from "@/views/AuditTrail/Show.vue";

import GroupIndex from "@/views/Group/Index.vue";
import GroupShow from "@/views/Group/Show.vue";
import GroupNew from "@/views/Group/New.vue";

import ServiceIndex from "@/views/Service/Index.vue";
import ServiceShow from "@/views/Service/Show.vue";
import ServiceCreate from "@/views/Service/New.vue";

import CouponIndex from "@/views/Coupon/Index.vue";
import CouponShow from "@/views/Coupon/Show.vue";
import CouponCreate from "@/views/Coupon/New.vue";


import SubscriptionIndex from "@/views/Subscription/Index.vue";
import SubscriptionShow from "@/views/Subscription/Show.vue";
import SubscriptionExpired from "@/views/Subscription/Expired.vue";
import SubscriptionCancelled from "@/views/Subscription/Cancelled.vue";
import SubscriptionProcessing from "@/views/Subscription/Processing.vue";
import SubscriptionPendingRefund from "@/views/Subscription/PendingRefund.vue";

import UserIndex from "@/views/User/Index.vue";
import UserShow from "@/views/User/Show.vue";

import TaskIndex from "@/views/Task/Index.vue";

import ReportSubscription from "@/views/Report/Subscription.vue";
import ReportPayment from "@/views/Report/Payment.vue";
import ReportService from "@/views/Report/Service.vue";
import ReportUser from "@/views/Report/User.vue";
import ReportExpense from "@/views/Report/Expense.vue";
import ReportForecast from "@/views/Report/Forecast.vue";
import ReportSummary from "@/views/Report/Summary.vue";

import ExpenseIndex from "@/views/Expense/Index.vue";
import ExpenseNew from "@/views/Expense/New.vue";

import AppIdIndex from "@/views/AppId/Index.vue";
import AppIdCreate from "@/views/AppId/Create.vue";

import ReferralIndex from "@/views/Referral/Index.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { layout: "empty", auth: false },
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
   meta: { auth: true },
  },
  {
    path: "/403",
    name: "Forbidden",
    component: Error403,
    meta: { layout: "empty", auth: false },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: { auth: ['admin', 'support'] },
  },
  {
    path: "/tasks",
    name: "Task",
    component: TaskIndex,
    meta: { auth: ['admin'] },
  },
  {
    path: "/services",
    name: "Service",
    component: ServiceIndex,
    meta: { auth: ['admin', 'support'] },
  },
  {
    path: "/services/new",
    name: "ServiceCreate",
    component: ServiceCreate,
    meta: { auth: ['admin', 'support'] },
  },
  {
    path: "/services/:id",
    name: "ServiceShow",
    component: ServiceShow,
    meta: { auth: ['admin', 'support'] },
  },
  {
    path: "/coupons",
    name: "Coupon",
    component: CouponIndex,
    meta: { auth: ['admin', 'support'] },
  },
  {
    path: "/coupons/new",
    name: "CouponCreate",
    component: CouponCreate,
    meta: { auth: ['admin', 'support'] },
  },
  {
    path: "/coupons/:id",
    name: "CouponShow",
    component: CouponShow,
    meta: { auth: ['admin', 'support'] },
  },
  {
    path: "/subscriptions",
    name: "Subscription",
    component: SubscriptionIndex,
    meta: { auth: ['admin', 'support'] },
  },
  {
    path: "/subscriptions/expired",
    name: "SubscriptionExpired",
    component: SubscriptionExpired,
    meta: { auth: ['admin', 'support'] },
  },
  {
    path: "/subscriptions/cancelled",
    name: "SubscriptionCancelled",
    component: SubscriptionCancelled,
    meta: { auth: ['admin', 'support'] },
  },
  {
    path: "/subscriptions/processing",
    name: "SubscriptionProcessing",
    component: SubscriptionProcessing,
    meta: { auth: ['admin', 'support'] },
  },
  {
    path: "/subscriptions/pending-refund",
    name: "SubscriptionPendingRefund",
    component: SubscriptionPendingRefund,
    meta: { auth: ['admin', 'support'] },
  },
  {
    path: "/subscriptions/:id",
    name: "SubscriptionShow",
    component: SubscriptionShow,
    meta: { auth: ['admin', 'support'] },
  },
  {
    path: "/groups",
    name: "Group",
    component: GroupIndex,
    meta: { auth: ['admin', 'support'] },
  },
  {
    path: "/groups/new",
    name: "GroupNew",
    component: GroupNew,
    meta: { auth: ['admin', 'support'] },
  },
  {
    path: "/groups/:id",
    name: "GroupShow",
    component: GroupShow,
    meta: { auth: ['admin', 'support'] },
  },
  {
    path: "/admins",
    name: "Admins",
    component: AdminIndex,
    meta: { auth: ['admin'] },
  },
  {
    path: "/admins/:id",
    name: "AdminShow",
    component: AdminShow,
    meta: { auth: ['admin'] },
  },
  {
    path: "/payments",
    name: "Payment",
    component: PaymentIndex,
    meta: { auth: ['admin', 'support'] },
  },
  {
    path: "/payments/:id",
    name: "PaymentShow",
    component: PaymentShow,
    meta: { auth: ['admin', 'support'] },
  },
  {
    path: "/users",
    name: "User",
    component: UserIndex,
    meta: { auth: ['admin', 'support'] },
  },
  {
    path: "/users/:id",
    name: "UserShow",
    component: UserShow,
    meta: { auth: ['admin', 'support'] },
  },
  {
    path: "/audit-trails",
    name: "AuditTrail",
    component: AuditTrailIndex,
    meta: { auth: ['admin'] },
  },
  {
    path: "/audit-trails/:id",
    name: "AuditTrailShow",
    component: AuditTrailShow,
    meta: { auth: ['admin'] },
  },
  {
    path: "/reports/subscriptions",
    name: "ReportSubscription",
    component: ReportSubscription,
    meta: { auth: ['admin'] },
  },
  {
    path: "/reports/payments",
    name: "ReportPayment",
    component: ReportPayment,
    meta: { auth: ['admin'] },
  },
  {
    path: "/reports/services",
    name: "ReportService",
    component: ReportService,
    meta: { auth: ['admin'] },
  },
  {
    path: "/reports/users",
    name: "ReportUser",
    component: ReportUser,
    meta: { auth: ['admin'] },
  },
  {
    path: "/reports/expenses",
    name: "ReportExpense",
    component: ReportExpense,
    meta: { auth: ['admin'] },
  },
  {
    path: "/reports/forecast",
    name: "ReportForecast",
    component: ReportForecast,
    meta: { auth: ['admin'] },
  },
  {
    path: "/reports/summary",
    name: "ReportSummary",
    component: ReportSummary,
    meta: { auth: ['admin'] },
  },
  {
    path: "/expenses",
    name: "ExpenseIndex",
    component: ExpenseIndex,
    meta: { auth: ['admin'] },
  },
  {
    path: "/expenses/new",
    name: "ExpenseNew",
    component: ExpenseNew,
    meta: { auth: ['admin'] },
  },
  {
    path: "/app_ids",
    name: "AppIdIndex",
    component: AppIdIndex,
    meta: { auth: ['admin'] },
  },
  {
    path: "/app_ids/create",
    name: "AppIdCreate",
    component: AppIdCreate,
    meta: { auth: ['admin'] },
  },
  {
    path: "/referrals",
    name: "ReferralIndex",
    component: ReferralIndex,
    meta: { auth: ['admin', 'support'] },
  },
  {
    path: "*",
    component: Error404,
  },

];

const router = new VueRouter({
  mode: 'history',
  baseUrl: process.env.BASE_URL,
  linkActiveClass: 'is-active',
  scrollBehavior: (t) => {
    return {
      y: 0
    }
  },
  routes
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start()
  }
  next();
});

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done();
})

export default router