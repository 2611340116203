export default {
  SET_GROUP(state, array) {
    state.group = array;
  },
  SET_META(state, data) {
    state.meta = data;
  },
  SET_GROUPS(state, obj) {
    state.groups = obj;
  },
}
