<template>
    <div :class="{ blur: !loaded }">
      <div class="p-2">
        <h3 class="text-gray-700 text-3xl font-semibold">Expense Report</h3>
        <h5>
          {{ toLocal(moreParams.created_at_from).format("ll") }} to
          {{ toLocal(moreParams.created_at_to).format("ll") }}
        </h5>
        <h6>
          <small
            ><i>Generated at {{ toLocal().format("lll") }}</i></small
          >
        </h6>
      </div>
  
      <div>
        <transition name="slide-fade">
          <div>
            <form class="d-form" @submit.prevent>
              <div class="grid grid-cols-6">
                <div>
                  <span class="text-bold text-opacity-pale">Group By</span>
                  <multiselect
                    v-model="moreParams['group_by']"
                    name="group_by"
                    :options="[
                      'YEAR(created_at)',
                      'MONTH(created_at)',
                      'WEEK(created_at)',
                      'DATE(created_at)',
                    ]"
                    :show-labels="false"
                  ></multiselect>
                </div>
  
                <div>
                  <span class="text-bold text-opacity-pale">Year</span>
                  <multiselect
                    :multiple="false"
                    v-model="moreParams['year']"
                    name="year"
                    :options="[2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]"
                    :show-labels="false"
                  ></multiselect>
                </div>
  
                <div>
                  <span class="text-bold text-opacity-pale">Month</span>
                  <multiselect
                    :multiple="false"
                    v-model="moreParams['month']"
                    name="month"
                    :options="Object.values(months)"
                    :show-labels="false"
                  ></multiselect>
                </div>

                <div>
                <span class="text-bold text-opacity-pale">Currency</span>
                <multiselect
                  :multiple="false"
                  name="currency"
                  @input="() => {setChartData(apiData.data); setDoughnutData(apiData.expenses)}"
                  v-model="currency"
                  :options="['USD', 'EUR']"
                  :show-labels="false"
                ></multiselect>
              </div>
  
                <div>
                  <span class="text-bold text-opacity-pale"
                    >Created At (From)</span
                  >
                  <input
                    type="date"
                    v-model="moreParams['created_at_from']"
                    class="
                      appearance-none
                      rounded-r rounded-l
                      sm:rounded-l-none
                      border border-gray-400 border-b
                      block
                      pl-8
                      pr-6
                      py-2
                      bg-white
                      text-sm
                      placeholder-gray-400
                      text-gray-700
                      focus:bg-white focus:placeholder-gray-600
                    "
                  />
                </div>
  
                <div>
                  <span class="text-bold text-opacity-pale">Created At (To)</span>
                  <input
                    type="date"
                    class="
                      appearance-none
                      rounded-r rounded-l
                      sm:rounded-l-none
                      border border-gray-400 border-b
                      block
                      pl-8
                      pr-6
                      py-2
                      bg-white
                      text-sm
                      placeholder-gray-400
                      text-gray-700
                      focus:bg-white focus:placeholder-gray-600
                    "
                    v-model="moreParams['created_at_to']"
                    name="created_at_to"
                  />
                </div>
              </div>
            </form>
          </div>
        </transition>
      </div>
  
      <div class="mt-8">
        <div class="mt-4">
          <div class="p-6 bg-white rounded-md shadow-md" v-if="chartData1">
            <LineChart :chart-data="chartData1" />
          </div>
        </div>
  
        <div class="mt-4">
          <div class="p-6 bg-white rounded-md shadow-md" v-if="chartData2">
            <Doughnut :chart-data="chartData2" />
          </div>
        </div>
      </div>
    </div>
  </template>
      
  <script>
  import { optional } from "@/helpers/global";
  import { toLocal, getDateRangeOfWeek } from "@/helpers/date";
  import LineChart from "@/components/Chart/Line.vue";
  import Doughnut from "@/components/Chart/Doughnut.vue";
  import moment from "moment";
  
  export default {
    title: "Expense | Reports",
    components: {
      LineChart,
      Doughnut
    },
    data() {
      return {
        loaded: false,
        loading: false,
        chartData1: null,
        chartData2: null,
        currency: null,
        apiData: [],
        moreParams: {
          group_by: "WEEK(created_at)",
          year: null,
          month: null,
          created_at_from: null,
          created_at_to: null,
        },
      };
    },
    methods: {
      toLocal,
      optional,
      formatLabel(datum, groupBy) {
        if (groupBy == "MONTH(created_at)") {
          return this.months[datum[groupBy]];
        } else if (groupBy == "YEAR(created_at)") {
          return datum[groupBy];
        } else if (groupBy == "WEEK(created_at)") {
          return getDateRangeOfWeek(datum[groupBy], this.moreParams.year);
        } else {
          return new Date(datum[groupBy]).toLocaleDateString();
        }
      },
      setChartData(data) {
        let labels = [];
        let datasets1 = [
          {
            label: "Expense Amount",
            backgroundColor: "red",
            data: [],
          },
        ];
  
        let prev = null;
        let percent = 0;
        let count = 0;
  
        for (let datum of data) {
          count = this.currency ? datum["totalAmount"]/datum.totalAmount : datum.totalAmount;
          if (prev) {
            percent = ((count - prev) / prev) * 100;
          }
          labels.push(
            `${this.formatLabel(datum, this.moreParams.group_by)} ${
              percent > 0
                ? `(+${parseFloat(percent).toFixed(2)}%)`
                : percent < 0
                ? `(${parseFloat(percent).toFixed(2)}%)`
                : ""
            }`
          );
          datasets1[0].data.push(count);
          prev = count;
        }
        this.chartData1 = {
          labels,
          datasets: datasets1,
        };
      },
      setDoughnutData(data) {
        let labels = [];
        let sum = data.reduce((a, b) => a + b.totalAmount, 0);
        let datasets = [
          {
            label: "Type",
            backgroundColor: [],
            data: [],
          },
        ];
        for (let datum of data) {
          labels.push(
            `${datum["type"] || 'N/A'} (${parseFloat(
              (datum.totalAmount / sum) * 100
            ).toFixed(2)}%) - ${this.currency ? this.rates[this.currency].symbol : "₦"}`
          );
          datasets[0].data.push(this.currency ? datum.totalAmount/this.rates[this.currency].rate : datum.totalAmount);
          datasets[0].backgroundColor.push(
            "#" + (((1 << 24) * Math.random()) | 0).toString(16).padStart(6, "0")
          );
        }
        this.chartData2 = {
          labels,
          datasets,
        };
      },
    },
    computed: {
      months() {
        return this.$store.getters["global/months"];
      },
      rates() {
      return this.$store.getters["global/rates"];
    },
    },
    watch: {
      moreParams: {
        handler(newValue, oldValue) {
          // Note: `newValue` will be equal to `oldValue` here
          // on nested mutations as long as the object itself
          // hasn't been replaced.
          this.loaded = false;
          this.chartData1 = null;
          this.chartData2 = null;
          this.$store.dispatch("expenses/getReport", newValue).then(({data, expenses}) => {
            this.apiData = {data, expenses}
            this.setChartData(data);
            this.setDoughnutData(expenses || []);
            this.loaded = true;
          });
        },
        deep: true,
      },
    },
    created() {
      let launchDate = moment(this.$store.getters["global/launchDate"]).startOf(
        "day"
      );
      let threeMonthsAgo = moment().subtract(3, "months").startOf("day");
      this.moreParams.created_at_from =
        launchDate.unix() > threeMonthsAgo.unix()
          ? launchDate.format("YYYY-MM-DD")
          : threeMonthsAgo.format("YYYY-MM-DD");
      this.moreParams.created_at_to = moment().format("YYYY-MM-DD");
      this.moreParams.year = new Date().getFullYear();
    },
  };
  </script>
  <style scoped>
  .checkbox-wrapper {
    padding: 32px 0 0 20px;
  }
  /* The container */
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #2196f3;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  </style>