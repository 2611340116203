const moment = require('moment')

export function toLocal (datetime) {
    return moment(datetime).local()
}

export function toUTC (datetime) {
    return moment(datetime).utc(datetime)
}

export function fromNow (datetime) {
    return moment(datetime).utc(datetime).local().fromNow();
}


export function getDateRangeOfWeek(weekNo, year){
    return `${new Date(year, 0, 1+((weekNo-1)*7)).toLocaleDateString()} to ${new Date(year, 0, (1+((weekNo-1)*7))+7).toLocaleDateString()}`;
};
