<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-semibold">
      {{ `User #${user.id}` }}
    </h3>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md">
          <form v-on:submit.prevent>
            <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">
              <div>
                <label class="text-gray-700" for="title"><b>Email : </b></label>
                <span class="">{{ user.email }} </span>
              </div>
              <div>
                <label class="text-gray-700" for="title"><b>Name : </b></label>
                <span class="">{{
                  `${user.first_name || ''} ${user.last_name || ''}`
                }}</span>
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Phone : </b></label>
                <span class="">{{ optional(user, 'phone', 'N/A') }} </span>
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Source : </b></label>
                <span class="">{{ optional(user, 'source', 'N/A') }} </span>
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Referrer : </b></label>
                <span class="">{{ optional(user, 'meta.referrer', 'N/A') }} </span>
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Creation Date : </b></label>
                <span class="">{{ toLocal(user.created_at).format('lll') }}</span>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Active : </b></label
                >
                <input
                  name="active"
                  type="checkbox"
                  v-model="body.active"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Subscriptions : </b></label>
                <router-link :to="{path: `/subscriptions`, query: {user_id: user.id}}">{{ optional(user, '$extras.subscriptions_count', 0) }}</router-link >
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>LTV : </b></label>
                <router-link :to="{path: `/payments`, query: {user_id: user.id}}">₦{{ user.$extras ? user.$extras.ltv : 0 | numberWithCommas }}</router-link>
              </div>

              <div v-if="user.active">
                <label class="text-gray-700" for="title"><b>Disable reason : </b></label>
                <textarea
                  name="disable_reason"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-model="body.disable_reason"
                ></textarea>
              </div>

              <div class="flex justify-end mt-4" v-if="$auth.user().id != user.id">
                <button v-if="!body.active || (body.active && body.disable_reason)"
                  :disabled="loading"
                  @click="submit()"
                  :class="`px-4 py-2 bg-gray-${loading ? 200 : 800} text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-${loading ? 100 : 700}`"
                >
                  {{user.active ? 'Disable' : 'Enable'}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { handleApiError } from "@/helpers/error";
import { toLocal } from "@/helpers/date";
import { optional } from "@/helpers/global"

export default {
  title:() => {
     return `User #${window.location.href.split('/').pop()} | Users`
  },
  data() {
    return {
      loaded: false,
      loading: false,
      body: {

      }
    };
  },
  methods: {
    toLocal,
    optional,
    update() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    submit() {
      this.body.active = !!this.body.active
      this.$store
        .dispatch("users/updateStatus", this.body)
        .then(({message, data}) => {
          this.$toast.success(message || `User status has been updated`, {
            position: "top-right",
            duration: 3000,
          });
          this.body = {
            id: data.id,
            disable_reason: data.meta.disable_reason,
            active: data.active
          }
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to update user");
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    user() {
      return this.$store.getters["users/user"];
    },
  },
  created() {
      this.$store.dispatch("users/getSingle", {
        id: this.$route.params.id,
        include: "roles,permissions",
      }).finally(() => {
        this.body = {
          id: this.user.id,
          disable_reason: this.user.meta.disable_reason,
          active: this.user.active
        }
      this.loaded = true;
    });
  },
};
</script>
