<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-semibold">
      {{ `Update Profile` }}
    </h3>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md">
          <form @submit.prevent="update" enctype="multipart/form-data">
            <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">
              <div>
                <label class="text-gray-700" for="title"
                  ><b>Username : </b></label
                >
                <span class="">{{ user.username }} </span>
              </div>
              <div>
                <label class="text-gray-700" for="title"><b>Email : </b></label>
                <input
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-validate="'required|email'"
                  v-model="user.email"
                />
                <small
                  class="form-text text-danger"
                  v-show="errors.has('email')"
                  >{{ errors.first("email") }}</small
                >
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>First Name : </b></label
                >
                <input
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-validate="'required'"
                  v-model="user.first_name"
                />
                <small
                  class="form-text text-danger"
                  v-show="errors.has('first_name')"
                  >{{ errors.first("first_name") }}</small
                >
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Last Name : </b></label
                >
                <input
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-validate="'required'"
                  v-model="user.last_name"
                />
                <small
                  class="form-text text-danger"
                  v-show="errors.has('last_name')"
                  >{{ errors.first("last_name") }}</small
                >
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Photo : </b></label>
                <input
                  type="file"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  accept="image/*"
                  id="file"
                  ref="file"
                  @input="importAndPreview"
                />
                <small
                  class="form-text text-danger"
                  v-show="errors.has('photo')"
                  >{{ errors.first("photo") }}</small
                >
              </div>

              <div v-if="filePreview">
                <img height="300px" width="100%" :src="filePreview" />
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Password : </b> <span @click="togglePassword('password')" >
           <i :class="type.password === 'password' ? 'zmdi zmdi-eye' : 'zmdi zmdi-eye-off'"></i>
            </span></label
                >
                <input
                  ref="password"
                  name="password"
                   :type="type.password"
                  class="form-input mt-1 mr-10 w-full rounded-md focus:border-indigo-600"
                  v-validate="'min:8'"
                  v-model="user.password"
                />
                 
         
                <small class="text-gray-200" v-show="errors.has('password')">{{
                  errors.first("password")
                }}</small>
              </div>

              <div v-if="user.password">
                <label class="text-gray-700" for="title"
                  ><b>Confirm Password : </b> <span @click="togglePassword('password_confirmation')" >
           <i :class="type.password_confirmation === 'password' ? 'zmdi zmdi-eye' : 'zmdi zmdi-eye-off'"></i>
            </span></label
                >
                <input
                  name="password_confirmation"
                  :type="type.password_confirmation"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-validate="'required|min:8|confirmed:password'"
                  v-model="user.password_confirmation"
                />
                

                <small
                  class="form-text text-danger"
                  v-show="errors.has('password_confirmation')"
                  >{{ errors.first("password_confirmation") }}</small
                >
                 
         </div>
       

               <div v-if="user.password">
                <label class="text-gray-700" for="title"
                  ><b>Current Password Password : </b>  <span @click="togglePassword('current_password')" >
           <i :class="type.current_password === 'password' ? 'zmdi zmdi-eye' : 'zmdi zmdi-eye-off'"></i>
            </span></label
                >
                <input
                  name="current_password"
                  :type="type.current_password"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-validate="'required|min:8'"
                  v-model="user.current_password"
                />
                
     
                <small
                  class="form-text text-danger"
                  v-show="errors.has('current_password')"
                  >{{ errors.first("current_password") }}</small
                >
              </div>

              <div class="flex justify-end mt-4">
                <button
                  :disabled="loading"
                  @click="update()"
                  :class="`px-4 py-2 bg-gray-${loading ? 200 : 800} text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-${loading ? 100 : 700}`"
                >
                  Update
                </button>

                <button
                  :disabled="loading"
                  @click="update(true)"
                  :class="`px-5 py-3 ml-2 bg-red-${loading ? 200 : 800} text-red-200 rounded-md hover:bg-red-700 focus:outline-none focus:bg-red-${loading ? 100 : 700}`"
                >
                  Update Password
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { handleApiError } from "@/helpers/error";

export default {
  title: "Show Profile",
  data() {
    return {
      loaded: false,
      loading: false,
      user: {},
      filePreview: null,
      type: {
        password: 'password',
        password_confirmation: 'password',
        current_password: 'password',
      }
    };
  },
  methods: {
    togglePassword(name) {
      this.type[name] = this.type[name] === "password" ? "text" : "password";
    },
    importAndPreview(event) {
      let reader = new FileReader();
      const myFile = event.target.files[0];

      let vm = this;
      reader.addEventListener(
        "load",
        function () {
          // convert image file to base64 string
          let result = reader.result;
          vm.user.file = {dataURL : result};
        },
        false
      );

      if (myFile) {
        this.filePreview = URL.createObjectURL(myFile);
        reader.readAsDataURL(myFile);
      }
    },
    update(isPassword) {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.loading = true;
          this.submit(typeof isPassword === 'boolean' ? isPassword : undefined);
        }
      });
    },
    submit(isPassword) {
      if(isPassword) {
        let confirmPasswordUpdate = confirm('You will be logged out on successful password update. Press OK to proceed.')
        
        if(!confirmPasswordUpdate) {
          this.loading = false;
          return;
        }
      }

      let handler = isPassword
      ? this.$store.dispatch("global/updatePassword", this.user)
      : this.$store.dispatch("global/updateProfile", this.user);
  

      handler
        .then(({message, data}) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
          isPassword ? this.$auth.logout() : this.$auth.refresh();
        })
        .catch((err) => {
          handleApiError(this, err, `Failed to update ${isPassword ? 'password' : 'profile'}`);
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {},
  created() {
    Object.assign(this.user, {
      id: this.$auth.user().id,
      first_name: this.$auth.user().first_name,
      last_name: this.$auth.user().last_name,
      email: this.$auth.user().email,
    });
    this.loaded = true;
  },
};
</script>
