<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-semibold">
      {{ `Group #${group.id}` }}
    </h3>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md">
          <form @submit.prevent="update">
            <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">
              <div>
                <label class="text-gray-700" for="title"
                  ><b>ServiceID : </b></label
                >
                <input
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="group.service_id"
                />
              </div>
              <div>
                <label class="text-gray-700" for="title"
                  ><b>Service : </b></label
                >
                <router-link v-if="group.service" :to="{ path: `/services/${group.service_id}` }">{{
                  group.service && group.service.name
                }}</router-link>
              </div>
              <div>
                <label class="text-gray-700" for="title"><b>Email : </b></label>
                <input
                  type="email"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="group.email"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Password : </b></label
                >
                <input
                  type="text"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="group.password"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Code : </b></label>
                 <wysiwyg
                    class="
                      form-input
                      w-full
                      mt-2
                      rounded-md
                      focus:border-indigo-600
                    "
                    v-model="group.code"
                  />
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>In Use : </b></label
                >
                <span>{{
                  group.__extras__ && group.__extras__.subscriptions_count
                    ? group.__extras__.subscriptions_count
                    : "No"
                }}</span>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Is Active : </b></label
                >
                <input type="checkbox" v-model="group.active" />
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Is Gift card : </b></label
                >
                <input type="checkbox" v-model="group.is_gift_card" />
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Has Login : </b></label
                >
                <input type="checkbox" v-model="group.has_login" />
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Limit : </b></label>
                <input
                  type="number"
                  min="1"
                  max="100"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="group.limit"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Plan : </b></label>
                <select
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="group.plan"
                >
                  <option value="shared">Shared</option>
                  <option value="personal">Personal</option>
                  <option value="family">Family</option>
                </select>
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Card : </b></label>
                <input
                  type="text"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="group.meta.card"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Phone : </b></label>
                <input
                  type="tel"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="group.meta.phone"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Billing Day : </b></label
                >
                <input
                  name="billing_day"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-model="group.meta.billing_day"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Last Updated : </b></label
                >
                <span class="">{{
                  toLocal(group.updated_at).format("lll")
                }}</span>
              </div>

              <div class="flex justify-end mt-4">
                <button
                  :disabled="loading"
                  @click="update"
                  :class="`px-4 py-2 bg-gray-${
                    loading ? 200 : 800
                  } text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-${
                    loading ? 100 : 700
                  }`"
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { handleApiError } from "@/helpers/error";
import { toLocal } from "@/helpers/date";

export default {
  title:() => {
     return `Group #${window.location.href.split('/').pop()} | Groups`
  },
  data() {
    return {
      loaded: false,
      loading: false,
      group: {
        meta: {
          card: null,
          phone: null,
        }
      },
    };
  },
  methods: {
    toLocal,
    update() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    submit() {
      let data = {};

      Object.assign(data, this.group);

      this.$store
        .dispatch("groups/update", data)
        .then(({ message }) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to update group");
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    currentGroup() {
      return this.$store.getters["groups/group"];
    },
  },
  created() {
    this.$store
      .dispatch("groups/getSingle", {
        id: this.$route.params.id,
        include: "user",
      })
      .finally(() => {
        this.group = this.currentGroup;
        this.group.meta = this.group.meta || {}
        this.loaded = true;
      });
  },
};
</script>
