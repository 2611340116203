export default {
  SET_APP_IDS(state, array) {
    state.appIds = array;
  },
  SET_META(state, data) {
    state.meta = data;
  },
  SET_APP_ID(state, obj) {
    state.appId = obj;
  },

}
