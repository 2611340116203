<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-semibold">Forecast</h3>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md">
          <form @submit.prevent="update">
            <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">
                <div>
                <label class="text-gray-700" for="title"><b>Title : </b></label>
                <input
                  type="text"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="group.title"
                />
              </div>
              <div>
                <label class="text-gray-700" for="title"><b>Start : </b></label>
                <input
                  type="date"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="group.start"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Initial Number : </b></label
                >
                <input
                  type="number"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="group.initial"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Currency: </b></label
                >
                <select
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="group.currency"
                >
                <option value="">None</option>
                  <option value="₦">NGN</option>
                  <option value="$">USD</option>
                  <option value="€">EUR</option>
                </select>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Duration (months) : </b></label
                >
                <input
                  type="number"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="group.duration"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Cost per unit : </b></label
                >
                <input
                  type="number"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="group.cost_per_unit"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Growth Rate (%) : </b></label
                >
                <input
                  type="number"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="group.rate"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Show Estimated Cost: </b></label
                >
                <input
                  type="checkbox"
                  v-model="group.show_estimate"
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="mt-4">
        <h2>RESULT</h2>
        <div class="p-6 bg-white rounded-md shadow-md">
          <div>
            <ul>
              <li>
                <b>Range</b>: {{ toLocal(group.start).format("MMMM YYYY") }} -
                {{
                  toLocal(group.start)
                    .add(group.duration, "months")
                    .format("MMMM YYYY")
                }}
              </li>
              <li>
                <b>Start</b>: {{ toLocal(group.start).format("MMMM YYYY") }}
              </li>
              <li>
                <b>Growth Rate</b>: {{ group.rate }}% per month
              </li>
              <li>
                <b>Initial</b>: {{ group.currency
                }}{{ parseFloat(group.initial).toFixed(2) | numberWithCommas }}
              </li>
            </ul>
          </div>
          <div>
            <h6>PROJECTION FOR {{ String(group.title).toUpperCase() }}</h6>

            <table class="center">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Number in Month</th>
                  <th v-if="group.show_estimate">Estimated cost</th>
                  <th>Total at month end</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(val, n) of cumulativeTotal(group).amounts"
                  :key="n"
                >
                  <td>
                    {{
                      toLocal(group.start).add(n, "months").format("MMMM YYYY")
                    }}
                  </td>
                  <td>
                   {{ group.currency }}{{ parseFloat(val.amount || 0).toFixed(2) | numberWithCommas }}
                  </td>
                  <td v-if="group.show_estimate">
                    {{ group.currency
                    }}{{ parseFloat((val.amount || 0) * group.cost_per_unit).toFixed(2) | numberWithCommas }}
                  </td>
                  <td>
                  {{ group.currency }}{{
                      parseFloat(val.cumulative || 0).toFixed(2) | numberWithCommas
                    }}
                  </td>
                </tr>
                <tr>
                    <td>TOTAL</td>
                    <td></td>
                    <td>{{ group.currency
                    }}{{ parseFloat(cumulativeTotal(group).amountTotal * group.cost_per_unit).toFixed(2) | numberWithCommas }}</td>
                    <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script >
import { toLocal } from "@/helpers/date";

export default {
  title: "Forecast | Reports",
  data() {
    return {
      loaded: true,
      group: {},
    };
  },
  methods: {
    toLocal,
    cumulativeTotal({duration, rate, initial: groupInitial}) {
      let initial = Number(groupInitial);
      let amounts = [{ amount: initial, cumulative: initial }];
      let amountTotal = 0

      for (let i = 0; i < duration; i++) {
        let prev = amounts[amounts.length - 1];
        let amount = prev.amount + prev.amount * (rate / 100);
        amounts.push({ amount, cumulative: prev.cumulative + amount });
        amountTotal += amount
      }
      return {amounts, amountTotal};
    },
  },
  computed: {},
  created() {},
};
</script>
  <style lang="scss" scoped>
.center {
  margin-left: auto;
  margin-right: auto;
}
table,
th,
td {
  border: 1px solid;
}
</style>