export default {
  SET_TASKS(state, array) {
    state.tasks = array;
  },
  SET_META(state, data) {
    state.meta = data;
  },
  SET_TASK(state, obj) {
    state.task = obj;
  },
}
