export default {
  SET_ADMINS(state, array) {
    state.admins = array;
  },
  SET_META(state, data) {
    state.meta = data;
  },
  SET_ADMIN(state, obj) {
    state.admin = obj;
  },

}
