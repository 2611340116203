<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-semibold">Create Group</h3>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md">
          <form @submit.prevent="update">
            <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">
              <div>
                <label class="text-gray-700" for="title"
                  ><b>Service ID : </b></label
                >
                <input
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="group.service_id"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Email : </b></label>
                <input
                  type="email"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="group.email"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Password/Gift Card Code : </b></label
                >
                <input
                  type="text"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="group.password"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Code : </b></label>
                 <wysiwyg
                    class="
                      form-input
                      w-full
                      mt-2
                      rounded-md
                      focus:border-indigo-600
                    "
                    v-model="group.code"
                  />
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Is Gift card : </b></label
                >
                <input type="checkbox" v-model="group.is_gift_card" />
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Has Login : </b></label
                >
                <input type="checkbox" v-model="group.has_login" />
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Limit : </b></label>
                <input
                  type="number"
                  min="1"
                  max="100"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="group.limit"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Plan : </b></label>
                <select
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="group.plan"
                >
                  <option value="shared">Shared</option>
                  <option value="personal">Personal</option>
                  <option value="family">Family</option>
                </select>
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Card : </b></label>
                <input
                  type="text"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="group.meta.card"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Phone : </b></label>
                <input
                  type="tel"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="group.meta.phone"
                />
              </div>

              <div class="flex justify-end mt-4">
                <button
                  :disabled="loading"
                  @click="save"
                  :class="`px-4 py-2 bg-gray-${
                    loading ? 200 : 800
                  } text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-${
                    loading ? 100 : 700
                  }`"
                >
                  Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script >
import { handleApiError } from "@/helpers/error";
import { toLocal } from "@/helpers/date";

export default {
  title: "Create Group | Groups",
  data() {
    return {
      loaded: false,
      loading: false,
      group: {
        has_login: false,
        plan: null,
        is_gift_card: false,
        meta: {
          card: null,
          phone: null,
        }
      },
    };
  },
  methods: {
    toLocal,
    save() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    submit() {
      let data = {};

      Object.assign(data, this.group);

      this.$store
        .dispatch("groups/create", data)
        .then(({ message }) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to create group");
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {},
  created() {
    this.loaded = true;
  },
};
</script>
  