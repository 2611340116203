<template>
  <div class="container-fluid">
    <div class="table pt-2 pb-4 pr-3">
      <div class="table-header">
        <div class="grid">
          <div>
            <small class="text-opacity"
              >{{ paginationData.total }} total expenses</small
            >
          </div>
        </div>
        <br />
        <filter-dropdown
          @setFilters="setFilters"
          @setCurrency="(val) => currency = val"
          :params="moreParams"
          :currency="currency"
        ></filter-dropdown>
        <button
          @click="clearFilters"
          :class="`px-2 py-3 bg-red-600 rounded-md text-white font-small tracking-wide hover:bg-red-200`"
        >
          Clear Filters
        </button>
        <router-link
        :to="{path: `/expenses/new`}"
          :class="`ml-4 px-3 py-3 bg-green-600 rounded-md text-white font-small tracking-wide hover:bg-green-200`"
        >
          New Expense
        </router-link>
      </div>
      <div
        class="
          align-middle
          inline-block
          shadow
          sm:rounded-lg
          border-b border-gray-200
        "
      >
        <vuetable
          ref="vuetable"
          :css="table_css.table"
          :api-url="api"
          :append-params="moreParams"
          :http-options="{
            headers: { Authorization: 'Bearer ' + $auth.token() },
          }"
          @vuetable:loaded="loadTable"
          @vuetable:cell-clicked="goto"
          :fields="columns.filter((s) => s.active === true)"
          data-path="data"
          pagination-path="meta"
          :no-data-template="
            rowCount === null ? 'Loading data' : 'No Data Available'
          "
          @vuetable:pagination-data="onPaginationData"
        >
          <template slot="sn" slot-scope="props">
            <router-link :to="{ path: `/subscriptions/${props.rowData.id}` }">{{
              props.rowIndex + 1
            }}</router-link>
          </template>

          <template slot="amount" slot-scope="props">
            {{ currency ? rates[currency].symbol : "₦"
            }}{{
              parseFloat(
                currency
                  ? props.rowData.amount / rates[currency].rate
                  : props.rowData.amount
              ).toFixed(2) | numberWithCommas
            }}
          </template>

          <template slot="actions" slot-scope="props" class="p-10">
            <div class="dropdown block relative">
              <button
                class="
                  bg-gray-300
                  text-gray-700
                  font-semibold
                  py-2
                  px-4
                  rounded
                  inline-flex
                  items-center
                "
              >
                <span class="mr-1">Actions</span>
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </button>
              <ul class="dropdown-menu hidden text-gray-700 pt-1">
                <a
                  href="#"
                  @click="confirmDeletion(props.rowData)"
                  :class="`rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap`"
                >
                  Delete
                </a>
              </ul>
            </div>
          </template>
        </vuetable>
        <vuetable-pagination
          v-show="paginationData.total"
          :css="table_css.pagination"
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        ></vuetable-pagination>
        <div class="text-center" v-if="rowCount <= 0 || rowCount === null">
          <slot name="empty"></slot>
        </div>
      </div>
    </div>
    <modal
      v-if="deleteAction"
      :open="deleteAction"
      @performAction="deleteItem"
      :model="model"
      :title="title"
      :description="description"
    ></modal>
  </div>
</template>
  
  <script>
import VuetableCss from "@/helpers/vuetable_styling.js";
import FilterDropdown from "./Filter";
import getPaginationData from "@/helpers/pagination";
import VuetablePagination from "@/components/VueTablePagination";
import Modal from "@/components/Modal";
import { toLocal } from "@/helpers/date";
import { handleApiError } from "@/helpers/error"

export default {
  title: "Expenses",
  components: { FilterDropdown, VuetablePagination, Modal },
  data() {
    return {
      title: null,
      description: null,
      model: null,
      deleteAction: false,
      rowCount: null,
      visibleFilter: false,
      table_css: VuetableCss,
      columns: [
        {
          name: "id",
          title: "ID",
          cvisible: true,
          sortField: "id",
          active: true,
        },
        {
          name: "__slot:amount",
          title: "Amount",
          sortField: "amount",
          cvisible: true,
          active: true,
        },
        {
          name: "type",
          title: "Type",
          cvisible: true,
          sortField: "type",
          active: true,
        },
        {
          name: "comment",
          title: "Comment",
          cvisible: true,
          active: true,
        },
        {
          name: "occurred_at",
          title: "Date",
          cvisible: true,
          active: true,
          sortField: "occurred_at",
          callback: (s) => {
            return s ? toLocal(s).format("lll") : "N/A";
          },
        },
        {
          name: "__slot:actions",
          title: "Actions",
          active: true,
          cvisible: false,
        },
      ],
      moreParams: {
        per_page: 100,
        page: 1,
      },
      currency: null,
      paginationData: {},
      api: `${this.$baseApi}/admin/expenses`,
    };
  },
  methods: {
    toLocal,
    getPaginationData,
    onPaginationData(paginationData) {
      this.paginationData = getPaginationData(paginationData, this.moreParams);
      this.$refs.pagination.setPaginationData(this.paginationData);
    },
    goto(e) {
      this.$router.push({ path: `/expenses/${e.id}` });
    },
    loadTable() {
      let d = this.$refs.vuetable.tableData;
      this.rowCount = d && d.length;
    },
    setFilters(data) {
      for (let [key, value] of Object.entries(this.moreParams)) {
        if (typeof value === "undefined") {
          delete this.moreParams[key];
        }

        switch (key) {
          case "created_at_from":
          case "created_at_to":
            if (value && value.split("T").length > 1)
              this.moreParams[key] = value;
            break;
          default:
            this.moreParams[key] = value;
            break;
        }
      }

      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    clearFilters() {
      this.moreParams = {
        per_page: 100,
        page: 1,
      };
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    onChangePage(page) {
      this.moreParams.page = page;
      this.setFilters();
    },
    refreshTable() {
      this.$refs.vuetable.refresh();
    },
    showVisibleFilter(d) {
      this.visibleFilter = d;
    },
    removeColumnFromTable(data) {
      this.columns.find((s) => s.name === data.name).active = data.val;
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.$refs.vuetable.normalizeFields();
      });
    },
    deleteItem({ id, status }) {
      this.$store
        .dispatch("expenses/delete", id)
        .then(() => {
          this.$toast.success(`Expense #${id} has been deleted`, {
            position: "top-right",
            duration: 3000,
          });
          this.refreshTable();
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to delete expense");
        })
        .finally(() => {
          this.model = {};
          this.title = "";
          this.description = "";
          this.deleteAction = false;
        });
    },
    confirmDeletion(model) {
      this.title = `Delete Item #${model.id}`;
      this.description = `Are you sure to delete item #${model.id}`;
      this.model = model;
      this.deleteAction = true;
    },
  },
  computed: {
    rates() {
      return this.$store.getters["global/rates"];
    },
  },
  created() {
    let moreParams = this.moreParams;
    this.moreParams = { ...moreParams, ...this.$route.query };

    this.loaded = true;
  },
};
</script>
  