<template>
  <div class="container-fluid">
    <div class="table pt-2 pb-4 pr-3">
      <div class="table-header">
        <div class="grid">
          <div>
            <small class="text-opacity"
              >{{ paginationData.total }} total trails</small
            >
          </div>
        </div>
        <br />
        <trail-filter
          @setFilters="setFilters"
          :params="moreParams"
        ></trail-filter>
        <button
          @click="clearFilters"
          :class="`px-6 py-3 bg-red-600 rounded-md text-white font-medium tracking-wide hover:bg-red-500`"
        >
          Clear Filters
        </button>
      </div>
      <div
        class="
          align-middle
          inline-block
          shadow
          sm:rounded-lg
          border-b border-gray-200
          w-full
          mt-5
          table-tt
        "
      >
        <vuetable
          ref="vuetable"
          class="w-full"
          :css="table_css.table"
          :api-url="api"
          :append-params="moreParams"
          :http-options="{
            headers: { Authorization: 'Bearer ' + $auth.token() },
          }"
          @vuetable:loaded="loadTable"
          @vuetable:cell-clicked="goto"
          :fields="columns.filter((s) => s.active === true)"
          data-path="data"
          pagination-path="meta"
          :no-data-template="
            rowCount === null ? 'Loading data' : 'No Data Available'
          "
          @vuetable:pagination-data="onPaginationData"
        >

          <template slot="creator" slot-scope="props">
            {{ props.rowData.user | optional("email", "N/A") }}
          </template>

          <template slot="user_ref" slot-scope="props">
            <router-link
              :to="{
                path: `/audit-trails`,
                query: { user_ref: `${props.rowData.user_ref}` },
              }"
              >{{ props.rowData.user_ref }}</router-link
            >
          </template>

          <template slot="uri" slot-scope="props">
            <router-link
              :to="{
                path: `/audit-trails`,
                query: { uri: `${props.rowData.uri}` },
              }"
              >{{ props.rowData.uri }}</router-link
            >
          </template>

          <template slot="actions" slot-scope="props" class="p-10">
            <div class="dropdown block relative">
              <button
                class="
                  bg-gray-300
                  text-gray-700
                  font-semibold
                  py-2
                  px-4
                  rounded
                  inline-flex
                  items-center
                "
              >
                <span class="mr-1">Actions</span>
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </button>
              <ul class="dropdown-menu hidden text-gray-700 pt-1">
                <li class="">
                  <router-link
                    :class="`rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap`"
                    :to="{ path: `/audit-trails/${props.rowData.id}` }"
                    >View</router-link
                  >
                </li>
              </ul>
            </div>
          </template>
        </vuetable>
        <vuetable-pagination
          v-show="paginationData.total"
          :css="table_css.pagination"
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        ></vuetable-pagination>
        <div class="text-center" v-if="rowCount <= 0 || rowCount === null">
          <slot name="empty"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuetableCss from "@/helpers/vuetable_styling.js";
import TrailFilter from "./Filter";
import getPaginationData from "@/helpers/pagination";
import VuetablePagination from "@/components/VueTablePagination";
import Modal from "@/components/Modal";
import { toLocal } from "@/helpers/date";

export default {
  title: "Audit Trail",
  components: { TrailFilter, VuetablePagination, Modal },
  data() {
    return {
      isOpen: {},
      title: null,
      description: null,
      model: null,
      rowCount: null,
      visibleFilter: false,
      table_css: VuetableCss,
      columns: [
        {
          name: "id",
          title: "ID",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:user_ref",
          title: "User Ref",
          sortField: "user_ref",
          cvisible: true,
          active: true,
        },

        {
          name: "__slot:creator",
          title: "Creator",
          sortField: "user_id",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:uri",
          title: "URI",
          sortField: "uri",
          cvisible: true,
          active: true,
        },
        {
          name: "time_taken",
          title: "Response Time",
          sortField: "time_taken",
          cvisible: true,
          active: true,
          callback: (s) => {
            return `${s} secs`;
          },
        },
        {
          name: "headers.origin",
          title: "Source",
          cvisible: true,
          active: true,
          callback: (s) => {
            return s.includes('backoffice') ? 'Admin' : (s.includes('mysub.io') ? 'Frontend' : 'Unknown');
          },
        },
        {
          name: "status_code",
          title: "Status",
          sortField: "status_code",
          cvisible: true,
          active: true,
          callback: (s) => {
            let color = this.getColor(s);
            return `<span class="bg-${color}-100 border border-${color}-400 text-${color}-700 px-4 py-3 rounded relative">${s}</span>`;
          },
        },

        {
          name: "created_at",
          title: "Creation Date",
          cvisible: true,
          active: true,
          sortField: "created_at",
          callback: (s) => {
            return s ? toLocal(s).format("lll") : "N/A";
          },
        },

        {
          name: "__slot:actions",
          title: "Actions",
          active: true,
          cvisible: false,
        },
      ],
      moreParams: {
        per_page: 100,
        search: null,
        status_code: [],
        method: [],
        page: 1,
        include: `creator`,
      },
      defaultParams: {
        per_page: 100,
        search: null,
        page: 1,
        status_code: [],
        method: [],
        include: `creator`,
      },
      paginationData: {},
      api: `${this.$baseApi}/admin/audit_trails`,
    };
  },
  methods: {
    toLocal,
    getPaginationData,
    onPaginationData(paginationData) {
      this.paginationData = getPaginationData(paginationData, this.moreParams);
      this.$refs.pagination.setPaginationData(this.paginationData);
    },
    goto(e) {
      this.$router.push({ path: `/audit-trails/${e.id}` });
    },
    loadTable() {
      let data = this.$refs.vuetable.tableData.map((el) => {
        this.isOpen[el.id] = null;
        return el;
      });

      this.$refs.vuetable.tableData = data;
      let d = this.$refs.vuetable.tableData;
      this.rowCount = d && d.length;
    },
    setFilters(data) {
      this.moreParams = { ...this.defaultParams, ...this.moreParams };
      for (let [key, value] of Object.entries(this.moreParams)) {
        if (typeof value === "undefined") {
          delete this.moreParams[key];
        }

        let data, newData;
        switch (key) {
          case "user":
            data = value ? value.map((s) => s.id) : [];
            this.moreParams[`user_id`] = [
              ...new Set([...this.moreParams[`user_id`], ...data]),
            ];

            break;

          case "created_at_from":
          case "created_at_to":
            this.moreParams[key] = value;
            break;
          default:
            this.moreParams[key] = value;
            break;
        }
      }

      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    clearFilters() {
      this.moreParams = {};
      this.$router.replace({
        name: this.$route.name,
        params: this.$route.params,
        query: {},
      });
      Object.assign(this.moreParams, this.defaultParams);

      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    onChangePage(page) {
      this.moreParams.page = page;
      this.setFilters();
    },
    refreshTable() {
      this.$refs.vuetable.refresh();
    },
    showVisibleFilter(d) {
      this.visibleFilter = d;
    },
    removeColumnFromTable(data) {
      this.columns.find((s) => s.name === data.name).active = data.val;
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.$refs.vuetable.normalizeFields();
      });
    },
    getColor(code) {
      let color;
      switch (code) {
        case 200:
        case 201:
          color = "green";
          break;
        case 400:
          color = "blue";
          break;
        case 401:
          color = "yellow";
          break;
        case 403:
          color = "orange";
          break;
        case 422:
          color = "purple";
          break;
        case 429:
          color = "pink";
          break;
        case 500:
          color = "red";
          break;
        default:
          color = "indigo";
          break;
      }

      return color;
    },
  },
  computed: {},
  created() {
    let moreParams = this.moreParams;

    this.moreParams = { ...moreParams, ...this.$route.query };
    this.loaded = true;
  },
};
</script>
