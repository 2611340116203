export default {
  SET_EXPENSES(state, array) {
    state.expenses = array;
  },
  SET_META(state, data) {
    state.meta = data;
  },
  SET_EXPENSE(state, obj) {
    state.expense = obj;
  },
  SET_REPORT(state, obj) {
    state.report = obj;
  },
}
