import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const auditTrails = {
  namespaced: true,
  state: {
    audit_trails: [],
    audit_trail: {},
    meta: {}
  },
  actions,
  getters,
  mutations
}
