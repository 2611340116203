export default {
  SET_USERS(state, array) {
    state.users = array;
  },
  SET_META(state, data) {
    state.meta = data;
  },
  SET_USER(state, obj) {
    state.user = obj;
  },
  SET_REPORT(state, obj) {
    state.report = obj;
  },
}
