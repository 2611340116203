import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const tasks = {
  namespaced: true,
  state: {
    tasks : [],
    task : {},
    meta : {}
  },
  actions,
  getters,
  mutations
}
