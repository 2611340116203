<template>
  <div class="container-fluid">
    <div class="table pt-2 pb-4 pr-3">
      <div class="table-header">
        <div class="grid">
          <div>
            <small class="text-opacity"
              >{{ paginationData.total }} total tasks</small
            >
          </div>
        </div>
        <br />
        <filter-dropdown
          @setFilters="setFilters"
          :params="moreParams"
        ></filter-dropdown>
        <button
          @click="clearFilters"
          :class="`px-2 py-3 bg-red-600 rounded-md text-white font-small tracking-wide hover:bg-red-200`"
        >
          Clear Filters
        </button>
      </div>
      <div
        class="align-middle inline-block shadow sm:rounded-lg border-b border-gray-200"
      >
        <vuetable
          ref="vuetable"
          :css="table_css.table"
          :api-url="api"
          :append-params="moreParams"
          :http-options="{
            headers: { Authorization: 'Bearer ' + $auth.token() },
          }"
          @vuetable:loaded="loadTable"
          :fields="columns.filter((s) => s.active === true)"
          data-path="data"
          pagination-path="meta"
          :no-data-template="
            rowCount === null ? 'Loading data' : 'No Data Available'
          "
          @vuetable:pagination-data="onPaginationData"
        >
          <template slot="sn" slot-scope="props">
            {{
              props.rowIndex + 1
            }}
          </template>


          <template slot="group" slot-scope="props">
            <router-link v-if="props.rowData.group" :to="{ path: `/groups/${props.rowData.group_id}` }">
              <span class="text-red-500">
            #{{props.rowData.group.id}}
            <br><small class="text-blue-500">{{props.rowData.group.email}}</small></span></router-link>
             <span v-else>N/A</span>
          </template>


          <template slot="subscription" slot-scope="props">
            <router-link v-if="props.rowData.subscription" :to="{ path: `/subscriptions/${props.rowData.subscription_id}` }">
              <span class="text-red-500">
            #{{props.rowData.subscription.id}}</span></router-link>
             <span v-else>N/A</span>
          </template>

          <template slot="service" slot-scope="props">
            <router-link v-if="props.rowData.subscription && props.rowData.subscription.service" :to="{ path: `/services/${props.rowData.subscription.service_id}` }">
              <span class="text-red-500">
            {{props.rowData.subscription.service.name}}
            </span>
            </router-link>
             <span v-else>N/A</span>
          </template>

            <template slot="actions" slot-scope="props">
            <div class="dropdown block relative">
              <button
                class="bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center"
              >
                <span class="mr-1">Actions</span>
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </button>
              <ul class="dropdown-menu hidden text-gray-700 pt-1">

               <a v-if="props.rowData.status !== 'COMPLETED'"
               href='#'
              @click="confirmComplete(props.rowData)"
              :class="`rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap`"
            >
              Mark as complete
            </a>
              
              </ul>
            </div>
          </template>
        </vuetable>
        <vuetable-pagination
          v-show="paginationData.total"
          :css="table_css.pagination"
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        ></vuetable-pagination>
        <div class="text-center" v-if="rowCount <= 0 || rowCount === null">
          <slot name="empty"></slot>
        </div>
      </div>
    </div>
    <modal v-if="deleteAction"
      :open="deleteAction"
      @performAction="deleteItem"
      :model="model"
      :title="title"
      :description="description"
    ></modal>
  </div>
</template>

<script>
import VuetableCss from "@/helpers/vuetable_styling.js";
import FilterDropdown from "./Filter";
import getPaginationData from "@/helpers/pagination";
import VuetablePagination from "@/components/VueTablePagination";
import Modal from "@/components/Modal";
import { toLocal } from "@/helpers/date";
import { handleApiError } from "@/helpers/error";

export default {
  title: "Tasks",
  components: { FilterDropdown, VuetablePagination, Modal },
  data() {
    return {
      title: null,
      description: null,
      model: null,
      deleteAction: false,
      rowCount: null,
      visibleFilter: false,
      table_css: VuetableCss,
      columns: [
      {
          name: "id",
          title: "ID",
          cvisible: true,
          active: true,
        },
        {
          name: "description",
          title: "Description",

          cvisible: true,
          active: true,
        },
        {
          name: "__slot:subscription",
          title: "Subscription",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:group",
          title: "Group",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:service",
          title: "Service",
          cvisible: true,
          active: true,
        },
        {
          name: "status",
          title: "Status",
          cvisible: true,
          active: true,
          callback : s => {
            let color = s === 'PENDING' ? 'red' : 'green';
            return `<span class="bg-${color}-100 border border-${color}-400 text-${color}-700 px-4 py-3 rounded relative">${s}</span>`
          }
        },
        {
          name: "completed_at",
          title: "Completed At",
          cvisible: true,
          active: true,
           sortField: "completed_at",
          callback: (s) => {
            return s ? toLocal(s).format("lll") : "N/A";
          },
        },
        {
          name: "created_at",
          title: "Creation Date",
          cvisible: true,
          active: true,
           sortField: "created_at",
          callback: (s) => {
            return s ? toLocal(s).format("lll") : "N/A";
          },
        },

        {
          name: "__slot:actions",
          title: "Actions",
          active: true,
          cvisible: false,
        },
      ],
      moreParams: {
        per_page: 100,
        user: null,
        page: 1,
      },
      paginationData: {},
      api: `${this.$baseApi}/admin/tasks`,
    };
  },
  methods: {
    toLocal,
    getPaginationData,
    onPaginationData(paginationData) {
      this.paginationData = getPaginationData(paginationData, this.moreParams);
      this.$refs.pagination.setPaginationData(this.paginationData);
    },
    loadTable() {
      let d = this.$refs.vuetable.tableData;
      this.rowCount = d && d.length;
    },
    setFilters(data) {
      for (let [key, value] of Object.entries(this.moreParams)) {
        if (typeof value === "undefined") {
          delete this.moreParams[key];
        }

        let data, newData;
        switch (key) {
          case "created_at_from":
          case "created_at_to":
            this.moreParams[key] = value;
            break;
          default:
            this.moreParams[key] = value;
            break;
        }
      }

      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    clearFilters() {
      this.moreParams = {};
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    onChangePage(page) {
      this.moreParams.page = page;
      this.setFilters();
    },
    refreshTable() {
      this.$refs.vuetable.refresh();
    },
    showVisibleFilter(d) {
      this.visibleFilter = d;
    },
    removeColumnFromTable(data) {
      this.columns.find((s) => s.name === data.name).active = data.val;
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.$refs.vuetable.normalizeFields();
      });
    },
    deleteItem({ id, status }) {
      this.$store
        .dispatch("tasks/update", id)
        .then(({  message }) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
          this.refreshTable();
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to update task");
        })
        .finally(() => {
          this.model = {};
          this.title = "";
          this.description = "";
          this.deleteAction = false;
        });
    },
    confirmComplete(model) {
      this.title = `Mark task #${model.id} as complete`;
      this.description = `Are you sure to mark task #${model.id} as complete`;
      this.model = model;
      this.deleteAction = true;
    },
  },
  computed: {

  },
  created() {
    let moreParams = this.moreParams;
    this.moreParams = { ...moreParams, ...this.$route.query };

    this.loaded = true;
  },
};
</script>
