<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-semibold">
      {{ `Log #${trail.reference}` }}
    </h3>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md">
          <form>
            <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">
              <div>
                <label class="text-gray-700" for="title"><b>Owner : </b></label>

                <router-link
                  :to="{ path: `/users/${trail.user_id}` }"
                  v-if="trail.creator"
                >
                  {{ optional(trail.creator, "email", "N/A") }}
                </router-link>
                <span class="" v-else>N/A</span>
              </div>
              <div>
                <label class="text-gray-700" for="title"
                  ><b>User Ref : </b></label
                >
                <span class="">{{ trail.user_ref }} </span>
              </div>
              <div>
                <label class="text-gray-700" for="title"
                  ><b>Method : </b></label
                >
                <span
                  :class="`bg-${methodColor}-100 border border-${methodColor}-400 text-${methodColor}-700 px-4 py-3 rounded relative`"
                  >{{ trail.method }}</span
                >
              </div>
              <div>
                <label class="text-gray-700" for="title"><b>URI : </b></label>
                <span>{{ trail.uri }}</span>
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>IP : </b></label>
                <span
                  ><a
                    rel="nofollow"
                    target="_blank"
                    :href="`https://www.ip2location.com/demo/${trail.ip}`"
                    >{{ trail.ip }}</a
                  ></span
                >
                <div v-if="ipData">
                  <ul v-for="(key, index) in Object.keys(ipData)" :key="index">
                    <li v-if="key != 'ip'">
                      <b>{{ key }}:</b> {{ ipData[key] }}
                    </li>
                  </ul>
                </div>
              </div>

              <div v-if="trail.description">
                <label class="text-gray-700" for="title"
                  ><b>Description : </b></label
                >
                <span>{{ trail.description }}</span>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Status : </b></label
                >
                <span
                  :class="`bg-${statusColor}-100 border border-${statusColor}-400 text-${statusColor}-700 px-4 py-3 rounded relative`"
                  >{{ trail.status_code }}</span
                >
              </div>

              <div class="is-json">
                <label class="text-gray-700" for="title"><b>Input : </b></label>
                <br />
                <code
                  v-html="
                    syntaxHighlight(JSON.stringify(trail.input, null, '\n'))
                  "
                >
                </code>
              </div>

              <div class="is-json">
                <label class="text-gray-700" for="title"
                  ><b>Headers : </b></label
                >
                <br />
                <code
                  v-html="
                    syntaxHighlight(JSON.stringify(trail.headers, null, 4))
                  "
                >
                </code>
              </div>

              <div class="is-json">
                <label class="text-gray-700" for="title"
                  ><b>Output : </b></label
                >
                <br />
                <code
                  v-html="
                    syntaxHighlight(JSON.stringify(trail.output, null, 4))
                  "
                >
                </code>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Response Time : </b></label
                >
                <span> {{ trail.time_taken }} secs </span>
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Date : </b></label>
                <span class="">{{
                  toLocal(trail.created_at).format("lll")
                }}</span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { optional } from "@/helpers/global";
import { toLocal } from "@/helpers/date";
import { handleApiError } from "@/helpers/error";

export default {
  title: "Show Trail | Audit Trail",
  data() {
    return {
      loaded: false,
      loading: false,
      ipData: null,
    };
  },
  methods: {
    toLocal,
    optional,
    resolveIpLocation(ip) {
      fetch(`https://ipapi.co/${ip.split(",").shift()}/json/`)
        .then((res) => res.json())
        .then((res) => {
          if (!res.error) {
            this.ipData = res;
          }
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to resolve user IP");
        });
    },
    syntaxHighlight(json) {
      json = json || "{}";
      json = json
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
      return json.replace(
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
        function (match) {
          let cls = "number";
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = "key";
            } else {
              cls = "string";
            }
          } else if (/true|false/.test(match)) {
            cls = "boolean";
          } else if (/null/.test(match)) {
            cls = "null";
          }
          return '<span class="' + cls + '">' + match + "</span>";
        }
      );
    },
  },
  computed: {
    trail() {
      return this.$store.getters["auditTrails/audit_trail"];
    },
    statusColor() {
      let code = this.trail.status_code;
      let color;
      switch (code) {
        case 200:
        case 201:
          color = "green";
          break;
        case 400:
          color = "blue";
          break;
        case 401:
          color = "yellow";
          break;
        case 403:
          color = "orange";
          break;
        case 422:
          color = "purple";
          break;
        case 429:
          color = "pink";
          break;
        case 500:
          color = "red";
          break;
        default:
          color = "indigo";
          break;
      }

      return color;
    },
    methodColor() {
      let code = this.trail.method;
      let color;
      switch (code) {
        case "GET":
          color = "green";
          break;
        case "POST":
          color = "blue";
          break;
        case "PATCH":
          color = "yellow";
          break;
        case "OPTIONS":
          color = "orange";
          break;
        case "HEAD":
          color = "purple";
          break;
          break;
        case "DELETE":
          color = "red";
          break;
        default:
          color = "indigo";
          break;
      }

      return color;
    },
  },
  created() {
    this.$store
      .dispatch("auditTrails/getSingle", {
        id: this.$route.params.id,
        include: "creator",
      })
      .then(() => {
        this.ipData = optional(this.trail, "meta.ip_location", null);
        this.ipData ||
          (this.trail.ip != "127.0.0.1" &&
            this.resolveIpLocation(this.trail.ip));
        this.loaded = true;
      });
  },
};
</script>
<style >
code {
  outline: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
}
.string {
  color: green;
}
.number {
  color: darkorange;
}
.boolean {
  color: blue;
}
.null {
  color: magenta;
}
.key {
  color: red;
}
.is-json {
  word-break: break-all;
}
</style>
