<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-semibold">
      {{ `Service #${service.id}` }}
    </h3>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md">
          <form @submit.prevent="update">
            <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">
              <div>
                <label class="text-gray-700" for="title"><b>Name : </b></label>
                <input
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="service.name"
                />
              </div>
              <div>
                <label class="text-gray-700" for="title"><b>Slug : </b></label>
                <span class="">{{ service.slug }}</span>
              </div>
              <div>
                <label class="text-gray-700" for="title"
                  ><b>Category : </b></label
                >
                <input
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="service.category"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Logo url : </b></label
                >
                <input
                  type="url"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="service.logo"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Is Family : </b></label
                >
                <input
                  type="checkbox"

                  v-model="service.is_family"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Is Active : </b></label
                >
                <input
                  type="checkbox"
                  v-model="service.active"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Require Email : </b></label
                >
                <input
                  type="checkbox"
                  v-model="service.require_email"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Price Personal Active : </b></label
                >
                <input
                  type="checkbox"

                  v-model="service.price_personal_active"
                />
              </div>

              <div v-if="service.price_personal_active">
                <label class="text-gray-700" for="title"
                  ><b>Price (Personal) : </b></label
                >
                <input
                  name="price_personal"
                  type="number"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="service.price_personal"
                />
                <small
                  class="text-gray-200"
                  v-show="errors.has('price_personal')"
                  >{{ errors.first("price_personal") }}</small
                >
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Price Shared Active : </b></label
                >
                <input
                  type="checkbox"

                  v-model="service.price_shared_active"
                />
              </div>

              <div v-if="service.price_shared_active">
                <label class="text-gray-700" for="title"
                  ><b>Price (Shared) : </b></label
                >
                <input
                  name="price_shared"
                  type="number"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="service.price_shared"
                />
                <small
                  class="text-gray-200"
                  v-show="errors.has('price_shared')"
                  >{{ errors.first("price_shared") }}</small
                >
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Price Family Active : </b></label
                >
                <input
                  type="checkbox"

                  v-model="service.price_family_active"
                />
              </div>

              <div v-if="service.price_family_active">
                <label class="text-gray-700" for="title"
                  ><b>Price (Family) : </b></label
                >
                <input
                  name="price_family"
                  type="number"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="service.price_family"
                />
                <small
                  class="text-gray-200"
                  v-show="errors.has('price_family')"
                  >{{ errors.first("price_family") }}</small
                >
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Features : </b></label
                >
                <textarea
                    name="features"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="service.features"
                ></textarea>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Auto assign : </b></label
                >
                <input
                  type="checkbox"

                  v-model="service.auto"
                />
              </div>

   <div>
                <label class="text-gray-700" for="title"
                  ><b>Subplans : </b></label
                >
                <textarea
                name="subplans"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="service.subplans"
                ></textarea>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Has Profile : </b></label
                >
                <input
                  type="checkbox"

                  v-model="service.has_profile"
                />
              </div>

              <div>
                  <label class="text-gray-700" for="title"
                    ><b>Description/Comment : </b></label
                  >
                  <wysiwyg
                    class="
                      form-input
                      w-full
                      mt-2
                      rounded-md
                      focus:border-indigo-600
                    "
                    v-model="service.description"
                  />
                </div>

                <div>
                  <label class="text-gray-700" for="title"
                    ><b>Buying Cost : </b></label
                  >
                  <input
                  type="number"
                    class="
                      form-input
                      w-full
                      mt-2
                      rounded-md
                      focus:border-indigo-600
                    "
                    v-model="service.meta.price"
                  />
                </div>

                <div>
                  <label class="text-gray-700" for="title"
                    ><b>Limit : </b></label
                  >
                  <input
                  type="number"
                    class="
                      form-input
                      w-full
                      mt-2
                      rounded-md
                      focus:border-indigo-600
                    "
                    v-model="service.meta.limit"
                  />
                </div>

                <div>
                  <label class="text-gray-700" for="title"
                    ><b>Max Limit Per Group : </b></label
                  >
                  <input
                  type="number"
                    class="
                      form-input
                      w-full
                      mt-2
                      rounded-md
                      focus:border-indigo-600
                    "
                    v-model="service.meta.max_limit_per_group"
                  />
                </div>

                <div>
                  <label class="text-gray-700" for="title"
                    ><b>Duration : </b></label
                  >
                  <input
                  type="number"
                    class="
                      form-input
                      w-full
                      mt-2
                      rounded-md
                      focus:border-indigo-600
                    "
                    v-model="service.meta.duration"
                  />
                </div>

                <div>
                  <label class="text-gray-700" for="title"
                    ><b>Minimum Months : </b></label
                  >
                  <select
                  type="number"
                    class="
                      form-input
                      w-full
                      mt-2
                      rounded-md
                      focus:border-indigo-600
                    "
                    v-model="service.meta.min"
                  >
                  <option :value="num" :key="index" v-for="(num,index) in [1,3,6,12,18,24]">{{ num }} month{{num > 1 ? 's' : '' }}</option>
                </select>
                </div>

                <div>
                  <label class="text-gray-700" for="title"
                    ><b>Maximum Months : </b></label
                  >
                  <select
                  type="number"
                    class="
                      form-input
                      w-full
                      mt-2
                      rounded-md
                      focus:border-indigo-600
                    "
                    v-model="service.meta.max"
                  >
                  <option :value="num" :key="index" v-for="(num,index) in [1,3,6,12,18,24]">{{ num }} month{{num > 1 ? 's' : '' }}</option>
                </select>
                </div>


              <div>
                <label class="text-gray-700" for="title"
                  ><b>Updated At : </b></label
                >
                <span class="">{{
                  toLocal(service.updated_at).format("lll")
                }}</span>
              </div>

              <div class="flex justify-end mt-4">
                <button
                  :disabled="loading"
                  @click="update"
                  :class="`px-4 py-2 bg-gray-${
                    loading ? 200 : 800
                  } text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-${
                    loading ? 100 : 700
                  }`"
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { handleApiError } from "@/helpers/error";
import { toLocal } from "@/helpers/date";

export default {
  title:() => {
     return `Service #${window.location.href.split('/').pop()} | Services`
  },
  data() {
    return {
      loaded: false,
      loading: false,
      service: {
        meta: {
          price: null,
          limit: null,
          duration: null,
          min: 1,
          max: 24,
        }
      }
    };
  },
  methods: {
    toLocal,
    update() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    submit() {
      let data = {};

      Object.assign(data, this.service);
      console.log(typeof data.features, data.features, typeof data.subplans, data.subplans)
      data.features = data.features ? data.features.split('|') : []
       data.subplans = data.subplans ? data.subplans.split('|').map((s) => {
          let d = s.split('-')
          return {
            name: d[0].trim(),
            amount: d[1].trim(),
          }
        }) : []

      this.$store
        .dispatch("services/update", data)
        .then(({ message }) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to update service");
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    currentService() {
      return this.$store.getters["services/service"];
    },
  },
  created() {
    this.$store
      .dispatch("services/getSingle", {
        id: this.$route.params.id,
        include: "user",
      })
      .finally(() => {
        this.loaded = true;
        Object.assign(this.service, this.currentService)
        this.service.features = this.service.features.join('|')
        this.service.subplans = this.service.subplans.map((s) => {
          return `${s.name} - ${s.amount}`
        }).join('|')
      });
  },
};
</script>
