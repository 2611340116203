<template>
  <div :class="{ blur: !loaded }">
    <div class="p-2">
      <h3 class="text-gray-700 text-3xl font-semibold">Subscription Report</h3>
      <h5>
        {{ toLocal(moreParams.created_at_from).format("ll") }} to
        {{ toLocal(moreParams.created_at_to).format("ll") }}
      </h5>
      <h6>
        <small
          ><i>Generated at {{ toLocal().format("lll") }}</i></small
        >
      </h6>
    </div>

    <div>
      <transition name="slide-fade">
        <div>
          <form class="d-form" @submit.prevent>
            <div class="grid grid-cols-6">
              <div>
                <span class="text-bold text-opacity-pale">Group By</span>
                <multiselect
                  v-model="moreParams['group_by']"
                  name="group_by"
                  :options="[
                    'YEAR(created_at)',
                    'MONTH(created_at)',
                    'WEEK(created_at)',
                    'DATE(created_at)',
                  ]"
                  :show-labels="false"
                ></multiselect>
              </div>

              <div>
                <span class="text-bold text-opacity-pale">Year</span>
                <multiselect
                  :multiple="false"
                  v-model="moreParams['year']"
                  name="year"
                  :options="[2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]"
                  :show-labels="false"
                ></multiselect>
              </div>

              <div>
                <span class="text-bold text-opacity-pale">Month</span>
                <multiselect
                  :multiple="false"
                  v-model="moreParams['month']"
                  name="month"
                  :options="Object.values(months)"
                  :show-labels="false"
                ></multiselect>
              </div>
              <div>
                <span class="text-bold text-opacity-pale">Currency</span>
                <multiselect
                  :multiple="false"
                  name="currency"
                  @input="setChartData(apiData)"
                  v-model="currency"
                  :options="['USD', 'EUR']"
                  :show-labels="false"
                ></multiselect>
              </div>

              <div>
                <span class="text-bold text-opacity-pale"
                  >Created At (From)</span
                >
                <input
                  type="date"
                  v-model="moreParams['created_at_from']"
                  class="
                    appearance-none
                    rounded-r rounded-l
                    sm:rounded-l-none
                    border border-gray-400 border-b
                    block
                    pl-8
                    pr-6
                    py-2
                    bg-white
                    text-sm
                    placeholder-gray-400
                    text-gray-700
                    focus:bg-white focus:placeholder-gray-600
                  "
                />
              </div>

              <div>
                <span class="text-bold text-opacity-pale">Created At (To)</span>
                <input
                  type="date"
                  class="
                    appearance-none
                    rounded-r rounded-l
                    sm:rounded-l-none
                    border border-gray-400 border-b
                    block
                    pl-8
                    pr-6
                    py-2
                    bg-white
                    text-sm
                    placeholder-gray-400
                    text-gray-700
                    focus:bg-white focus:placeholder-gray-600
                  "
                  v-model="moreParams['created_at_to']"
                  name="created_at_to"
                />
              </div>
            </div>
          </form>
        </div>
      </transition>
    </div>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md" v-if="chartData1">
          <LineChart :chart-data="chartData1" />
        </div>
      </div>

      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md" v-if="chartData2">
          <LineChart :chart-data="chartData2" />
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script >
import { optional } from "@/helpers/global";
import { toLocal, getDateRangeOfWeek } from "@/helpers/date";
import LineChart from "@/components/Chart/Line.vue";
import moment from "moment";

export default {
  title: "Subscription | Reports",
  components: {
    LineChart,
  },
  data() {
    return {
      loaded: false,
      loading: false,
      chartData1: null,
      chartData2: null,
      currency: null,
      apiData: [],
      moreParams: {
        group_by: "WEEK(created_at)",
        year: null,
        month: null,
        created_at_from: null,
        created_at_to: null,
      },
    };
  },
  methods: {
    toLocal,
    optional,
    formatLabel(datum, groupBy) {
      if (groupBy == "MONTH(created_at)") {
        return this.months[datum[groupBy]];
      } else if (groupBy == "YEAR(created_at)") {
        return datum[groupBy];
      } else if (groupBy == "WEEK(created_at)") {
        return getDateRangeOfWeek(datum[groupBy], this.moreParams.year);
      } else {
        return new Date(datum[groupBy]).toLocaleDateString();
      }
    },
    setChartData(data) {
      let labels1 = [];
      let labels2 = [];
      let datasets1 = [
        {
          label: "Subscription Count",
          backgroundColor: "red",
          data: [],
        },
      ];
      let datasets2 = [
        {
          label: `Subscription Amount (${
            this.currency ? this.rates[this.currency].symbol : "₦"
          }) `,
          backgroundColor: "green",
          data: [],
        },
      ];

      let prev1 = null
      let prev2 = null
      let percent1 = 0
      let percent2 = 0
      let count = 0
      let amount = 0

      for (let datum of data) {
        count = datum["totalCount"]
        amount =  this.currency
            ? datum["totalAmount"] / this.rates[this.currency].rate
            : datum["totalAmount"]
        if(prev1) {
          percent1 = ((count - prev1)/prev1) * 100
        }
        if(prev2) {
          percent2 = ((amount - prev2)/prev2) * 100
        }
        labels1.push(`${this.formatLabel(datum, this.moreParams.group_by)} ${percent1 > 0 ? `(+${parseFloat(percent1).toFixed(2)}%)` : percent1 < 0 ? `(${parseFloat(percent1).toFixed(2)}%)` : ''}`);
        labels2.push(`${this.formatLabel(datum, this.moreParams.group_by)} ${percent2 > 0 ? `(+${parseFloat(percent2).toFixed(2)}%)` : percent2 < 0 ? `(${parseFloat(percent2).toFixed(2)}%)` : ''}`);
        
        datasets1[0].data.push(count);
        datasets2[0].data.push(amount);
        prev1 = count
        prev2 = amount
      }
      this.chartData1 = {
        labels: labels1,
        datasets: datasets1,
      };
      this.chartData2 = {
        labels: labels2,
        datasets: datasets2,
      };
    },
  },
  computed: {
    rates() {
      return this.$store.getters["global/rates"];
    },
    months() {
      return this.$store.getters["global/months"];
    },
  },
  watch: {
    moreParams: {
      handler(newValue, oldValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        this.chartData1 = null;
      this.chartData2 = null;
        this.loaded = false;
        this.$store
          .dispatch("subscriptions/getReport", newValue)
          .then((data) => {
            this.apiData = data;
            this.setChartData(data);
            this.loaded = true;
          });
      },
      deep: true,
    },
  },
  created() {
    let launchDate = moment(this.$store.getters["global/launchDate"]).startOf(
      "day"
    );
    let threeMonthsAgo = moment().subtract(3, "months").startOf("day");
    this.moreParams.created_at_from =
      launchDate.unix() > threeMonthsAgo.unix()
        ? launchDate.format("YYYY-MM-DD")
        : threeMonthsAgo.format("YYYY-MM-DD");
    this.moreParams.created_at_to = moment().format("YYYY-MM-DD");
    this.moreParams.year = new Date().getFullYear();
  },
};
</script>
  