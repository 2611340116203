import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const appIds = {
  namespaced: true,
  state: {
    appIds : [],
    appId : {},
    meta : {}
  },
  actions,
  getters,
  mutations
}
