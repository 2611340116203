<template>
  <div class="container-fluid">
    <div class="table pt-2 pb-4 pr-3">
      <div class="table-header">
        <div class="grid">
          <div>
            <small class="text-opacity"
              >{{ paginationData.total }} total appIds</small
            >
          </div>
        </div>
        <br />
        <filter-dropdown
          @setFilters="setFilters"
          :params="moreParams"
        ></filter-dropdown>
        <button
          :class="`px-2 py-3 bg-red-600 rounded-md text-white font-medium tracking-wide hover:bg-red-200`"
          @click="clearFilters()"
        >
          Clear Filters
        </button>
        <button
          @click="$router.push({ path: `app_ids/create` })"
          :class="`px-2 py-3 bg-green-600 rounded-md text-white font-small tracking-wide hover:bg-green-200`"
        >
          Add New
        </button>
        <div class="stat" v-if="response">
          <span
            class="bg-green-100 border border-green-400 text-green-700 px-1 py-1"
            >publicKey: {{response.publicKey}}
          </span> | 
          <span
            class="bg-green-100 border border-green-400 text-green-700 px-1 py-1"
            >encryptionKey: {{response.encryptionKey}}
          </span>
          </div>
      </div>
      <div
        class="align-middle inline-block shadow sm:rounded-lg border-b border-gray-200"
      >
        <vuetable
          ref="vuetable"
          :css="table_css.table"
          :api-url="api"
          :append-params="moreParams"
          :http-options="{
            headers: {
              Authorization: 'Bearer ' + $auth.token(),
              'app-id': $appId,
            },
          }"
          @vuetable:loaded="loadTable"
          @vuetable:cell-clicked="goto"
          :fields="columns.filter((s) => s.active === true)"
          data-path="data"
          pagination-path="meta"
          :no-data-template="
            rowCount === null ? 'Loading data' : 'No Data Available'
          "
          @vuetable:pagination-data="onPaginationData"
        >
          <template slot="sn" slot-scope="props">
            {{ props.rowIndex + 1 }}
          </template>

          <template slot="actions" slot-scope="props">
            <button
              :class="`px-2 py-3 bg-red-600 rounded-md text-white font-medium tracking-wide hover:bg-red-200`"
              @click="confirmDelete(props.rowData)"
            >
              Delete
            </button>
            <button
              @click="confirmRevoke(props.rowData)"
              :class="`px-2 py-3 bg-green-600 rounded-md text-white font-small tracking-wide hover:bg-green-200`"
            >
              {{ props.rowData.active ? "Revoke" : "Activate" }}
            </button>

            <button v-if="props.rowData.active"
              @click="confirmRefresh(props.rowData)"
              :class="`px-2 py-3 bg-blue-600 rounded-md text-white font-small tracking-wide hover:bg-blue-200`"
            >
              Refresh
            </button>
          </template>
        </vuetable>
        <vuetable-pagination
          v-show="paginationData.total"
          :css="table_css.pagination"
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        ></vuetable-pagination>
        <div class="text-center" v-if="rowCount <= 0 || rowCount === null">
          <slot name="empty"></slot>
        </div>
      </div>
    </div>
    <modal
      v-if="deleteAction"
      :open="deleteAction"
      @performAction="deleteItem"
      :model="model"
      :title="title"
      :description="description"
    ></modal>
    <modal
      v-else-if="revokeAction"
      :open="revokeAction"
      @performAction="revokeItem"
      :model="model"
      :title="title"
      :description="description"
    ></modal>
    <modal
      v-else-if="refreshAction"
      :open="refreshAction"
      @performAction="refreshItem"
      :model="model"
      :title="title"
      :description="description"
    ></modal>
  </div>
</template>

<script>
import VuetableCss from "@/helpers/vuetable_styling.js";
import FilterDropdown from "./Filter";
import getPaginationData from "@/helpers/pagination";
import VuetablePagination from "@/components/VueTablePagination";
import { toLocal } from "@/helpers/date";
import Modal from "@/components/Modal";
import { optional } from "@/helpers/global";
import { handleApiError } from "@/helpers/error";
export default {
  title: "App IDs",
  components: { FilterDropdown, VuetablePagination, Modal },
  data() {
    return {
      rowCount: null,
      visibleFilter: false,
      table_css: VuetableCss,
      response: null,
      columns: [
        {
          name: "id",
          title: "ID",
          cvisible: true,
          active: true,
        },
        {
          name: "name",
          title: "Name",
          cvisible: true,
          active: true,
        },
        {
          name: "email",
          title: "Email",
          cvisible: true,
          active: true,
        },
        {
          name: "description",
          title: "Description",
          cvisible: true,
          active: true,
        },
        {
          name: "domain",
          title: "Domain",
          cvisible: true,
          active: true,
        },
        {
          name: "webhook",
          title: "Webhook",
          cvisible: true,
          active: true,
        },
        {
          name: "active",
          title: "Active",
          cvisible: true,
          active: true,
          callback: (s) => {
            let color = s ? "green" : "red";
            return `<span class="bg-${color}-100 border border-${color}-400 text-${color}-700 px-4 py-3 rounded relative">${
              s ? "Yes" : "No"
            }</span>`;
          },
        },
        {
          name: "updated_at",
          title: "Updated At",
          cvisible: true,
          active: true,
          sortField: "created_at",
          callback: (s) => {
            return s ? toLocal(s).format("lll") : "N/A";
          },
        },
        {
          name: "__slot:actions",
          title: "Actions",
          active: true,
          cvisible: false,
        },
      ],
      moreParams: {
        per_page: 100,
        page: 1,
        service_id: [],
      },
      paginationData: {},
      api: `${this.$baseApi}/admin/app_ids`,
      title: null,
      description: null,
      model: null,
      deleteAction: false,
      revokeAction: false,
      refreshAction: false,
    };
  },
  methods: {
    toLocal,
    getPaginationData,
    optional,
    onPaginationData(paginationData) {
      this.paginationData = getPaginationData(paginationData, this.moreParams);
      this.$refs.pagination.setPaginationData(this.paginationData);
    },
    goto(e) {
      this.$router.push({ path: `/app_ids/${e.id}` });
    },
    loadTable() {
      let d = this.$refs.vuetable.tableData;
      this.rowCount = d && d.length;
    },
    setFilters(d) {
      for (let [key, value] of Object.entries(this.moreParams)) {
        if (typeof value === "undefined") {
          delete this.moreParams[key];
        }

        let data;
        switch (key) {
          case "created_at_from":
          case "created_at_to":
            if (value && value.split("T").length > 1)
              this.moreParams[key] = value;
            break;
          default:
            this.moreParams[key] = value;
            break;
        }
      }
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    clearFilters() {
      this.moreParams = {
        per_page: 100,
        page: 1,
      };
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    onChangePage(page) {
      this.moreParams.page = page;
      this.setFilters();
    },
    refreshTable() {
      this.$refs.vuetable.refresh();
    },
    showVisibleFilter(d) {
      this.visibleFilter = d;
    },
    removeColumnFromTable(data) {
      this.columns.find((s) => s.name === data.name).active = data.val;
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.$refs.vuetable.normalizeFields();
      });
    },
    deleteItem({ id }) {
      this.$store
        .dispatch("appIds/delete", id)
        .then(() => {
          this.$toast.success(`AppId ${id} has been deleted`, {
            position: "top-right",
            duration: 3000,
          });
          this.refreshTable();
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to delete");
        })
        .finally(() => {
          this.model = {};
          this.title = "";
          this.description = "";
          this.deleteAction = false;
        });
    },
    revokeItem({ active, id }) {
      this.$store
        .dispatch("appIds/update", { id, active: !active })
        .then(({ message }) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
          this.refreshTable();
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to revoke/activate");
        })
        .finally(() => {
          this.model = {};
          this.title = "";
          this.description = "";
          this.revokeAction = false;
        });
    },
    refreshItem({ id }) {
      this.$store
        .dispatch("appIds/refresh", { id })
        .then(({ data, message }) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
          this.response = data
          this.refreshTable();
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to refresh appId");
        })
        .finally(() => {
          this.model = {};
          this.title = "";
          this.description = "";
          this.refreshAction = false;
        });
    },
    confirmDelete(model) {
      this.title = `Delete AppId #${model.id}`;
      this.description = `Are you sure to delete appId #${model.id}`;
      this.model = model;
      this.deleteAction = true;
    },
    confirmRevoke(model) {
      this.title = `${model.active ? "Revoke" : "Activate"} AppId #${model.id}`;
      this.description = `Are you sure to ${
        model.active ? "revoke" : "activate"
      } appId #${model.id}`;
      this.model = model;
      this.revokeAction = true;
    },
    confirmRefresh(model) {
      this.title = `Refresh AppId #${model.id}`;
      this.description = `Are you sure to refresh appId #${model.id}`;
      this.model = model;
      this.refreshAction = true;
    },
  },
  computed: {},
  created() {
    let moreParams = this.moreParams;
    this.moreParams = { ...moreParams, ...this.$route.query };
    this.loaded = true;
  },
};
</script>
<style scoped>
.stat {
  padding: 2px;
}
</style>