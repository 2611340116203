export default {
  SET_AUDIT_TRAILS(state, array) {
    state.audit_trails = array;
  },
  SET_META(state, data) {
    state.meta = data;
  },
  SET_AUDIT_TRAIL(state, obj) {
    state.audit_trail = obj;
  },
}
