<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-semibold">Create Coupon</h3>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md">
          <form @submit.prevent="update">
            <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">
              <div>
                <label class="text-gray-700" for="title"><b>Name : </b></label>
                <input type="text"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-model="coupon.name"
                  v-validate="'required'"
                />
                <small
                  class="text-gray-200 text-danger"
                  v-show="errors.has('name')"
                  >{{ errors.first("name") }}</small
                >
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Code : </b></label>
                <input type="text" v-validate="'required|min:8'"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-model="coupon.code"
                />
                <small
                  class="text-gray-200 text-danger"
                  v-show="errors.has('code')"
                  >{{ errors.first("code") }}</small
                >
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Discount Type : </b></label
                >
                <select v-validate="'required'"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-model="coupon.discount_type"
                >
                  <option value="percent">Percent (%)</option>
                  <option value="amount">Amount (₦)</option>
                </select>
                <small
                  class="text-gray-200 text-danger"
                  v-show="errors.has('discount_type')"
                  >{{ errors.first("discount_type") }}</small
                >
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Is Active : </b></label
                >
                <input type="checkbox" v-model="coupon.active" />
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Discount Amount : </b></label
                >
                <input
                  name="discount_amount"
                  type="number"
                  v-validate="`required|min_value:1|max_value:${coupon.discount_type === 'percent' ? 100 : 500}`"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-model="coupon.discount_amount"
                />
                <small
                  class="text-gray-200 text-danger"
                  v-show="errors.has('discount_amount')"
                  >{{ errors.first("discount_amount") }}</small
                >
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Limit : </b></label>
                <input
                  type="number"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-model="coupon.limit"
                />
                <small
                  class="text-gray-200 text-danger"
                  v-show="errors.has('limit')"
                  >{{ errors.first("limit") }}</small
                >
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Expires At : </b></label
                >
                <input
                  type="date"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-model="coupon.expires_at"
                />
                <small
                  class="text-gray-200 text-danger"
                  v-show="errors.has('expires_at')"
                  >{{ errors.first("expires_at") }}</small
                >
              </div>

              <div class="flex justify-end mt-4">
                <button
                  :disabled="loading"
                  @click="save"
                  :class="`px-4 py-2 bg-gray-${
                    loading ? 200 : 800
                  } text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-${
                    loading ? 100 : 700
                  }`"
                >
                  Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script >
import { handleApiError } from "@/helpers/error";
import { toLocal } from "@/helpers/date";
import { random } from "@/helpers/global";

export default {
  title: "Create Coupon | Coupons",
  data() {
    return {
      loaded: false,
      loading: false,
      coupon: {
        name: null,
        code: random().toUpperCase(),
        limit: null,
        discount_type: null,
        discount_amount: null,
        expires_at: null,
      },
    };
  },
  methods: {
    toLocal,
    save() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    submit() {
      let data = {};

      Object.assign(data, this.coupon);
      this.$store
        .dispatch("coupons/create", data)
        .then(({ message }) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
          this.coupon.code = random().toUpperCase()
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to create coupon");
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {},
  created() {
    this.loaded = true;
  },
};
</script>
  