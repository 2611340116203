<template>
<div class="mt-5">
  <h3 class="text-gray-700 text-3xl font-medium">404</h3>
    <h4 class="text-gray-700 text-3xl font-medium">Page Not Found</h4>
    <h4 class="text-gray-700 text-3xl font-medium"><router-link :to="{name: 'Dashboard'}">Go Home</router-link></h4>
</div>
</template>
<script>
export default {
  title : 'Page Not Found'
}
</script>
