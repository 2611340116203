import axios from 'axios';

const API = `${process.env.VUE_APP_BASE_URI}/admin`;

export default {
    index({
        commit
    }, obj) {
        return new Promise((resolve, reject) => {
            let url = `${API}/subscriptions?${new URLSearchParams(Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))).toString()}`;
            axios.get(url)
                .then(async ({
                    data
                }) => {

                    commit('SET_SUBSCRIPTIONS', data.data);
                    commit('SET_META', data.meta);
                    resolve(data.data);
                })
                .catch((error) => {
                    reject(error.response)
                });
        });
    },
    getSingle({
        commit
    }, { id, include = '' }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/subscriptions/${id}?include=${include}`;
            axios.get(url)
                .then(async ({
                    data: { data }
                }) => {
                    commit('SET_SUBSCRIPTION', data);

                    resolve(data);
                })
                .catch((error) => {
                    reject(error.response)
                });
        });
    },
    update({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/subscriptions/${data.id}`;
            axios.patch(url, data)
                .then(async ({
                    data
                }) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error.response)
                });
        });
    },
    getReport({
        commit
    }, obj = {}) {
        return new Promise((resolve, reject) => {
            
            let url = `${API}/subscriptions/report?${new URLSearchParams(Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))).toString()}`;
            axios.get(url)
                .then(async ({
                    data
                }) => {

                    commit('SET_REPORT', data.data);
                    resolve(data.data);
                })
                .catch((error) => {
                    reject(error.response)
                });
        });
    },
    cancel({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            let url = `${API}/subscriptions/${data.id}/cancel`;
            axios.patch(url, data)
                .then(async ({
                    data
                }) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error.response)
                });
        });
    },
}
