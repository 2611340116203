<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-semibold">Create Expense</h3>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md">
          <form @submit.prevent="update">
            <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">
              <div>
                <label class="text-gray-700" for="title"
                  ><b>Amount : </b></label
                >
                <input
                  type="number"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="expense.amount"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Type : </b></label>
                <multiselect
                  :multiple="false"
                  name="type"
                  v-model="expense.type"
                  :options="['subscription', 'marketing', 'salary', 'others']"
                  :show-labels="false"
                ></multiselect>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Occurred At : </b></label
                >
                <input
                  type="date"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="expense.occurred_at"
                />
              </div>

              <div>
                <label class="text-gray-700" for="comment"
                  ><b>Comment : </b></label
                >
                <textarea
                  v-validate="'max:255'"
                  class="
                    form-input
                    w-full
                    mt-2
                    rounded-md
                    focus:border-indigo-600
                  "
                  v-model="expense.comment"
                ></textarea>
              </div>

              <div class="flex justify-end mt-4">
                <button
                  :disabled="loading"
                  @click="save"
                  :class="`px-4 py-2 bg-gray-${
                    loading ? 200 : 800
                  } text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-${
                    loading ? 100 : 700
                  }`"
                >
                  Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { handleApiError } from "@/helpers/error";
import { toLocal } from "@/helpers/date";

export default {
  title: "Create Expense | Expenses",
  data() {
    return {
      loaded: false,
      loading: false,
      expense: {},
    };
  },
  methods: {
    toLocal,
    save() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    submit() {
      let data = {};

      Object.assign(data, this.expense);

      this.$store
        .dispatch("expenses/create", data)
        .then(({ message }) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
          this.$router.push({ path: `/expenses` })
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to create expense");
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {},
  created() {
    this.loaded = true;
  },
};
</script>
