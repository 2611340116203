/**
 * This would be the home of our stores.
 */
import { global } from './global/store';
import { admins } from './admins/store';
import { users } from './users/store';
import { payments } from './payments/store';
import { auditTrails } from './auditTrails/store';
import { subscriptions } from './subscriptions/store';
import { services } from './services/store';
import { groups } from './groups/store';
import { tasks } from './tasks/store';
import { expenses } from './expenses/store';
import { reports } from './reports/store';
import { coupons } from './coupons/store';
import { appIds } from './appIds/store';


export default {
  modules: {
      global,
      admins,
      payments,
      auditTrails,
      users,
      subscriptions,
      services,
      groups,
      tasks,
      expenses,
      reports,
      coupons,
      appIds,
  },

};
