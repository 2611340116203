<template>
  <div class="container-fluid">
    <div class="table pt-2 pb-4 pr-3">
      <div class="table-header">
        <div class="grid">
          <div>
            <small class="text-opacity"
              >{{ paginationData.total }} total users</small
            >
          </div>
        </div>
        <br />
        <filter-dropdown
          @setFilters="setFilters"
          :params="moreParams"
        ></filter-dropdown>
        <button
          @click="clearFilters"
          :class="`px-2 py-3 bg-red-600 rounded-md text-white font-small tracking-wide hover:bg-red-200`"
        >
          Clear Filters
        </button>
      </div>
      <div class="stat">
        <span
          class="bg-green-100 border border-green-400 text-green-700 px-1 py-1"
          >Average LTV:
        </span>
        <span class="bg-red-100 border border-red-400 text-red-700 px-1 py-1"
          >{{
            $route.query.currency
              ? rates[$route.query.currency].symbol
              : "₦"
          }}{{
            parseFloat(
              $route.query.currency
                ? averageLtv / rates[$route.query.currency].rate
                : averageLtv
            ).toFixed(2) | numberWithCommas
          }}/User
        </span>
      </div>
      <div
        class="
          align-middle
          inline-block
          shadow
          sm:rounded-lg
          border-b border-gray-200
        "
      >
        <vuetable
          ref="vuetable"
          :css="table_css.table"
          :api-url="api"
          :append-params="moreParams"
          :http-options="{
            headers: { Authorization: 'Bearer ' + $auth.token() },
          }"
          @vuetable:loaded="loadTable"
          @vuetable:cell-clicked="goto"
          :fields="columns.filter((s) => s.active === true)"
          data-path="data"
          pagination-path="meta"
          :no-data-template="
            rowCount === null ? 'Loading data' : 'No Data Available'
          "
          @vuetable:pagination-data="onPaginationData"
        >
          <template slot="sn" slot-scope="props">
            <router-link :to="{ path: `/admins/${props.rowData.id}` }">{{
              props.rowIndex + 1
            }}</router-link>
          </template>

          <template slot="name" slot-scope="props">
            {{ props.rowData.first_name || '' }} {{ props.rowData.last_name || ''}}
          </template>

          <template slot="source" slot-scope="props">
            {{ props.rowData.source }}
            <span v-if="props.rowData.meta && props.rowData.meta.source_detail"
              >: {{ props.rowData.meta.source_detail }}</span
            >
          </template>

          <template slot="ltv" slot-scope="props">
            {{
              $route.query.currency ? rates[$route.query.currency].symbol : "₦"
            }}{{
              parseFloat(
                $route.query.currency
                  ? (props.rowData.$extras.ltv || 0) /
                      rates[$route.query.currency].rate
                  : props.rowData.$extras.ltv || 0
              ).toFixed(2) | numberWithCommas
            }}
          </template>

          <template slot="actions" slot-scope="props">
            <div class="dropdown block relative">
              <button
                class="
                  bg-gray-300
                  text-gray-700
                  font-semibold
                  py-2
                  px-4
                  rounded
                  inline-flex
                  items-center
                "
              >
                <span class="mr-1">Actions</span>
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </button>
              <ul class="dropdown-menu hidden text-gray-700 pt-1">
                <li class="">
                  <router-link
                    :class="`rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap`"
                    :to="{ path: `/users/${props.rowData.id}` }"
                    >View</router-link
                  >
                </li>
                <li class="">
                  <router-link
                    :class="`rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap`"
                    :to="{
                      path: `/subscriptions`,
                      query: { user_id: props.rowData.id },
                    }"
                    >Subscriptions</router-link
                  >
                </li>
                <li class="">
                  <router-link
                    :class="`rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap`"
                    :to="{
                      path: `/payments`,
                      query: { user_id: props.rowData.id },
                    }"
                    >Payments</router-link
                  >
                </li>
              </ul>
            </div>
          </template>
        </vuetable>
        <vuetable-pagination
          v-show="paginationData.total"
          :css="table_css.pagination"
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        ></vuetable-pagination>
        <div class="text-center" v-if="rowCount <= 0 || rowCount === null">
          <slot name="empty"></slot>
        </div>
      </div>
    </div>
    <modal
      v-if="deleteAction"
      :open="deleteAction"
      @performAction="deleteItem"
      :model="model"
      :title="title"
      :description="description"
    ></modal>
  </div>
</template>

<script>
import VuetableCss from "@/helpers/vuetable_styling.js";
import FilterDropdown from "./Filter";
import getPaginationData from "@/helpers/pagination";
import VuetablePagination from "@/components/VueTablePagination";
import Modal from "@/components/Modal";
import { toLocal } from "@/helpers/date";

export default {
  title: "Users",
  components: { FilterDropdown, VuetablePagination, Modal },
  data() {
    return {
      title: null,
      description: null,
      model: null,
      deleteAction: false,
      rowCount: null,
      visibleFilter: false,
      table_css: VuetableCss,
      columns: [
        {
          name: "id",
          title: "ID",
          cvisible: true,
          active: true,
        },
        {
          name: "email",
          title: "Email",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:name",
          title: "Name",
          cvisible: true,
          active: true,
        },
        {
          name: "phone",
          title: "Phone",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:source",
          title: "Source",
          cvisible: true,
          active: true,
        },
        {
          name: "meta.referrer",
          title: "Referrer",
          cvisible: true,
          active: true,
          callback: (s) => {
            return s || "N/A";
          },
        },
        {
          name: "$extras.subscriptions_count",
          title: "Subscriptions",
          cvisible: true,
          active: true,
          callback: (s) => {
            return s || 0;
          },
        },
        {
          name: "meta.subscriptions_reminder",
          title: "Reminders",
          cvisible: true,
          active: true,
          callback: (s) => {
            return s || 0;
          },
        },
        {
          name: "__slot:ltv",
          title: "LTV",
          cvisible: true,
          active: true,
        },
        {
          name: "last_login_at",
          title: "Last Login",
          cvisible: true,
          sortField: "last_login_at",
          active: true,
          callback: (s) => {
            return s ? toLocal(s).format("lll") : "N/A";
          },
        },
        {
          name: "created_at",
          title: "Creation Date",
          cvisible: true,
          active: true,
          sortField: "created_at",
          callback: (s) => {
            return s ? toLocal(s).format("lll") : "N/A";
          },
        },

        {
          name: "__slot:actions",
          title: "Actions",
          active: true,
          cvisible: false,
        },
      ],
      moreParams: {
        per_page: 100,
        user: null,
        role_id: [],
        page: 1,
      },
      paginationData: {},
      api: `${this.$baseApi}/admin/users`,
      averageLtv: 0,
      totalLtv: 0,
    };
  },
  methods: {
    toLocal,
    getPaginationData,
    onPaginationData(paginationData) {
      this.paginationData = getPaginationData(paginationData, this.moreParams);
      this.$refs.pagination.setPaginationData(this.paginationData);
    },
    goto(e) {
      this.$router.push({ path: `/users/${e.id}` });
    },
    loadTable() {
      let d = this.$refs.vuetable.tableData;
      d && this.calculateMetric(d);
      this.rowCount = d && d.length;
    },
    setFilters(data) {
      for (let [key, value] of Object.entries(this.moreParams)) {
        if (typeof value === "undefined") {
          delete this.moreParams[key];
        }

        let data, newData;
        switch (key) {
          case "created_at_from":
          case "created_at_to":
            this.moreParams[key] = value;
            break;
          default:
            this.moreParams[key] = value;
            break;
        }
      }

      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    clearFilters() {
      this.moreParams = {};
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    onChangePage(page) {
      this.moreParams.page = page;
      this.setFilters();
    },
    refreshTable() {
      this.$refs.vuetable.refresh();
    },
    showVisibleFilter(d) {
      this.visibleFilter = d;
    },
    removeColumnFromTable(data) {
      this.columns.find((s) => s.name === data.name).active = data.val;
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.$refs.vuetable.normalizeFields();
      });
    },
    calculateMetric(users) {
      this.totalLtv = 0;
      for (let user of users) {
        this.totalLtv += user.$extras.ltv;
      }
      this.averageLtv = this.totalLtv / users.length;
    },
  },
  computed: {},
  created() {
    let moreParams = this.moreParams;
    this.moreParams = { ...moreParams, ...this.$route.query };

    this.loaded = true;
  },
};
</script>
<style scoped>
.stat {
  padding: 2px;
}
</style>