<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-semibold">
      {{ `Coupon #${coupon.id}` }}
    </h3>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md">
          <form @submit.prevent="update">
            <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">
              <div>
                <label class="text-gray-700" for="title"><b>Name : </b></label>
                <input
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-model="coupon.name"
                  v-validate="'required'"
                />
                <small
                  class="text-gray-200 text-danger"
                  v-show="errors.has('name')"
                  >{{ errors.first("name") }}</small
                >
              </div>
              <div>
                <label class="text-gray-700" for="title"><b>Code : </b></label>
                <span class="">{{ coupon.code }}</span>
              </div>
              <div>
                <label class="text-gray-700" for="title"
                  ><b>Discount Type : </b></label
                >
                <span class="">{{ coupon.discount_type }}</span>
              </div>
              <div>
                <label class="text-gray-700" for="title"
                  ><b>Discount Amount : </b></label
                >
                <span class="">{{
                  coupon.discount_type === "percent"
                    ? `${coupon.discount_amount}%`
                    : `₦${coupon.discount_amount}`
                }}</span>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Is Active : </b></label
                >
                <input type="checkbox" v-model="coupon.active" />
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Limit : </b></label>
                <input
                  type="number"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-model="coupon.limit"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Expires At : </b></label
                >
                <input
                  type="date"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-model="coupon.expires_at"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Creation Date : </b></label
                >
                <span class="">{{
                  toLocal(coupon.created_at).format("lll")
                }}</span>
              </div>

              <div class="flex justify-end mt-4">
                <button
                  :disabled="loading"
                  @click="update"
                  :class="`px-4 py-2 bg-gray-${
                    loading ? 200 : 800
                  } text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-${
                    loading ? 100 : 700
                  }`"
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { handleApiError } from "@/helpers/error";
import { toLocal } from "@/helpers/date";
import moment from "moment"

export default {
  title: () => {
    return `Coupon #${window.location.href.split("/").pop()} | Coupons`;
  },
  data() {
    return {
      loaded: false,
      loading: false,
      coupon: {},
    };
  },
  methods: {
    toLocal,
    update() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    submit() {
      let data = {};

      Object.assign(data, this.coupon);

      this.$store
        .dispatch("coupons/update", data)
        .then(({ message }) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to update coupon");
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    currentCoupon() {
      return this.$store.getters["coupons/coupon"];
    },
  },
  created() {
    this.$store
      .dispatch("coupons/getSingle", {
        id: this.$route.params.id,
      })
      .finally(() => {
        this.loaded = true;
        Object.assign(this.coupon, this.currentCoupon);
        this.coupon.expires_at = moment(this.coupon.expires_at ).format('YYYY-MM-DD')
      });
  },
};
</script>
