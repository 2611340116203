export default {
  SET_COUPONS(state, array) {
    state.coupons = array;
  },
  SET_META(state, data) {
    state.meta = data;
  },
  SET_COUPON(state, obj) {
    state.coupon = obj;
  },
}
