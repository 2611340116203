<template>
  <div class="container-fluid">
    <div class="table pt-2 pb-4 pr-3">
      <div class="table-header">
        <div class="grid">
          <div>
            <small class="text-opacity"
              >{{ paginationData.total }} total payment transactions</small
            >
          </div>
        </div>
        <br />
        <filter-dropdown
          @setFilters="setFilters"
          :params="moreParams"
        ></filter-dropdown>
        <button
          :class="`px-2 py-3 bg-red-600 rounded-md text-white font-medium tracking-wide hover:bg-red-200`"
          @click="clearFilters()"
        >
          Clear Filters
        </button>
        <div class="stat" v-if="hasRole($auth.user(), 'admin')">
          <span
            class="bg-green-100 border border-green-400 text-green-700 px-1 py-1"
            >Total days since launch:
          </span>
          <span
            class="bg-red-100 border border-red-400 text-red-700 px-1 py-1"
            >{{ daysAgo }}</span
          >
          |
          <span
            class="bg-green-100 border border-green-400 text-green-700 px-1 py-1"
            >Total Subscriptions:
          </span>
          <span
            class="bg-red-100 border border-red-400 text-red-700 px-1 py-1"
            >{{ paginationData.total }}</span
          >
          |
          <span
            class="bg-green-100 border border-green-400 text-green-700 px-1 py-1"
            >Total Processed: </span
          > <span
            class="bg-red-100 border border-red-400 text-red-700 px-1 py-1"
            >{{
              $route.query.currency
                ? rates[$route.query.currency].symbol
                : currency && currency.symbol
            }}{{
              parseFloat(
                $route.query.currency
                  ? totalAmount / rates[$route.query.currency].rate
                  : totalAmount
              ).toFixed(2) | numberWithCommas
            }}</span
          > |
          <span
            class="bg-green-100 border border-green-400 text-green-700 px-1 py-1"
            >Margin:
          </span>
          <span class="bg-red-100 border border-red-400 text-red-700 px-1 py-1"
            >{{
              $route.query.currency
                ? rates[$route.query.currency].symbol
                : currency && currency.symbol
            }}{{
              parseFloat(
                $route.query.currency
                  ? margin / rates[$route.query.currency].rate
                  : margin
              ).toFixed(2) | numberWithCommas
            }} </span
          > |
          <span
            class="bg-green-100 border border-green-400 text-green-700 px-1 py-1"
            >Margin per month:
          </span>
          <span class="bg-red-100 border border-red-400 text-red-700 px-1 py-1"
            >{{
              $route.query.currency
                ? rates[$route.query.currency].symbol
                : currency && currency.symbol
            }}{{
              parseFloat(
                $route.query.currency
                  ? marginPerMonth / rates[$route.query.currency].rate
                  : marginPerMonth
              ).toFixed(2) | numberWithCommas
            }}
          </span>
        </div>
        <div class="stat" v-if="hasRole($auth.user(), 'admin')">
          <span
            class="bg-green-100 border border-green-400 text-green-700 px-1 py-1"
          >
            Average sub per day:
          </span>
          <span class="bg-red-100 border border-red-400 text-red-700 px-1 py-1"
            >{{ paginationData.total }}/{{ daysAgo }}={{
              parseInt(paginationData.total / daysAgo)
            }}</span
          >
          |
          <span
            class="bg-green-100 border border-green-400 text-green-700 px-1 py-1"
            >Average processed per day:
          </span>
          <span class="bg-red-100 border border-red-400 text-red-700 px-1 py-1"
            >{{
              $route.query.currency
                ? rates[$route.query.currency].symbol
                : currency && currency.symbol
            }}{{
              parseFloat(
                $route.query.currency
                  ? totalAmount / daysAgo / rates[$route.query.currency].rate
                  : totalAmount / daysAgo
              ).toFixed(2) | numberWithCommas
            }}</span
          >
          |
          <span
            class="bg-green-100 border border-green-400 text-green-700 px-1 py-1"
            >Average Margin per day:
          </span>
          <span class="bg-red-100 border border-red-400 text-red-700 px-1 py-1"
            >{{
              $route.query.currency
                ? rates[$route.query.currency].symbol
                : currency && currency.symbol
            }}{{
              parseFloat(
                $route.query.currency
                  ? margin / daysAgo / rates[$route.query.currency].rate
                  : margin / daysAgo
              ).toFixed(2) | numberWithCommas
            }}</span
          >
          |
          <span
            class="bg-green-100 border border-green-400 text-green-700 px-1 py-1"
            >Average Margin per subscription:
          </span>
          <span class="bg-red-100 border border-red-400 text-red-700 px-1 py-1"
            >{{
              $route.query.currency
                ? rates[$route.query.currency].symbol
                : currency && currency.symbol
            }}{{
              parseFloat(
                $route.query.currency
                  ? margin /
                      paginationData.total /
                      rates[$route.query.currency].rate
                  : margin / paginationData.total
              ).toFixed(2) | numberWithCommas
            }}</span
          >
        </div>
      </div>
      <div
        class="align-middle inline-block shadow sm:rounded-lg border-b border-gray-200"
      >
        <vuetable
          ref="vuetable"
          :css="table_css.table"
          :api-url="api"
          :append-params="moreParams"
          :http-options="{
            headers: { Authorization: 'Bearer ' + $auth.token() },
          }"
          @vuetable:loaded="loadTable"
          @vuetable:cell-clicked="goto"
          :fields="columns.filter((s) => s.active === true)"
          data-path="data"
          pagination-path="meta"
          :no-data-template="
            rowCount === null ? 'Loading data' : 'No Data Available'
          "
          @vuetable:pagination-data="onPaginationData"
        >
          <template slot="sn" slot-scope="props">
            {{ props.rowIndex + 1 }}
          </template>

          <template slot="user" slot-scope="props">
            <router-link
              :to="{ path: `/users/${props.rowData.user_id}` }"
            >
              <span class="text-red-500">
                {{
                  `${props.rowData.user.first_name || ''} ${props.rowData.user.last_name || ''}`
                }}
                <br /><small class="text-blue-500">{{
                  props.rowData.user.email
                }}</small></span
              ></router-link
            >
          </template>

          <template slot="status" slot-scope="props">
          <span :class="`bg-${props.rowData.status === 'successful' ? 'green' : 'red'}-100 border border-${props.rowData.status === 'successful' ? 'green' : 'red'}-400 text-${props.rowData.status === 'successful' ? 'green' : 'red'}-700 px-4 py-3 rounded relative`">
          {{props.rowData.subscription && props.rowData.subscription.cancelled_at && props.rowData.user && props.rowData.user.meta && props.rowData.user.meta.is_external && toLocal(props.rowData.subscription.cancelled_at).diff(toLocal(props.rowData.subscription.created_at), 'h') < 24*15 ? 'cancelled' : props.rowData.status }}
          </span>
          </template>

          <template slot="subscription" slot-scope="props">
            <router-link
              :to="{ path: `/subscriptions/${props.rowData.subscription_id}` }"
              v-if="props.rowData.subscription"
              >#{{ props.rowData.subscription_id }}
            </router-link>
            <br>
            <small v-if="props.rowData.subscription && props.rowData.subscription.cancelled_at && props.rowData.user && props.rowData.user.meta && props.rowData.user.meta.is_external && toLocal(props.rowData.subscription.cancelled_at).diff(toLocal(props.rowData.subscription.created_at), 'h') < 24*15 && props.rowData.user && props.rowData.user.meta && props.rowData.user.meta.is_external"><i>cancelled at {{toLocal(props.rowData.subscription.cancelled_at).format('lll')}}</i></small>
          </template>

          <template slot="discount" slot-scope="props">
            <router-link :to="{ path: `/coupons/${props.rowData.coupon.id}`}" v-if="props.rowData.coupon">{{props.rowData.coupon.name}} {{ props.rowData.coupon.discount_type === 'percent' ? `${props.rowData.coupon.discount_amount}%` : `₦${props.rowData.coupon.discount_amount}` }}</router-link>
            <span v-else-if="props.rowData.meta.discount"><i>{{ props.rowData.meta.discount.name }} - {{ `${props.rowData.meta.discount.discount_percent }%` }}</i></span>
          </template>

          <template slot="service" slot-scope="props">
            <router-link
              v-if="
                props.rowData.subscription && props.rowData.subscription.service
              "
              :to="{
                path: `/services/${props.rowData.subscription.service_id}`,
              }"
            >
              {{ props.rowData.subscription.service.name }}
            </router-link>
            <span v-else>N/A</span>
          </template>

          <template slot="reference" slot-scope="props">
            <a
              :href="`https://dashboard.paystack.com/#/transactions/${props.rowData.ext_ref}/analytics`"
              target="_blank"
              rel="nofollow"
            >
              {{ props.rowData.reference }}
            </a>
          </template>

          <template slot="amount" slot-scope="props">
            {{
              $route.query.currency
                ? rates[$route.query.currency].symbol
                : props.rowData.currency && props.rowData.currency.symbol
            }}{{
              parseFloat(
                $route.query.currency
                  ? props.rowData.amount / rates[$route.query.currency].rate
                  : props.rowData.amount
              ).toFixed(2) | numberWithCommas
            }}
          </template>

          <template slot="fee" slot-scope="props">
            {{
              $route.query.currency
                ? rates[$route.query.currency].symbol
                : props.rowData.currency && props.rowData.currency.symbol
            }}{{
              parseFloat(
                $route.query.currency
                  ? props.rowData.fee / rates[$route.query.currency].rate
                  : props.rowData.fee
              ).toFixed(2) | numberWithCommas
            }}
          </template>
          <template slot="amountPerUnit" slot-scope="props">
            {{
              $route.query.currency
                ? rates[$route.query.currency].symbol
                : props.rowData.currency && props.rowData.currency.symbol
            }}{{
              parseFloat(
                $route.query.currency
                  ? getAmountByPlanType(props.rowData.subscription) /
                      rates[$route.query.currency].rate
                  : getAmountByPlanType(props.rowData.subscription)
              ).toFixed(2) | numberWithCommas
            }}
          </template>
          <template slot="amountDue" slot-scope="props">
            {{
              $route.query.currency
                ? rates[$route.query.currency].symbol
                : props.rowData.currency && props.rowData.currency.symbol
            }}{{
              parseFloat(
                $route.query.currency
                  ? (getAmountByPlanType(props.rowData.subscription) *
                  optional(props.rowData, 'subscription.months', 0)) /
                      rates[$route.query.currency].rate
                  : getAmountByPlanType(props.rowData.subscription) *
                      optional(props.rowData, 'subscription.months', 0)
              ).toFixed(2) | numberWithCommas
            }}
          </template>

          <template slot="margin" slot-scope="props">
            {{
              $route.query.currency
                ? rates[$route.query.currency].symbol
                : props.rowData.currency && props.rowData.currency.symbol
            }}{{
              parseFloat(
                $route.query.currency
                  ? (props.rowData.amount -
                      getBuyingPrice(props.rowData.subscription) -
                      props.rowData.fee) /
                      rates[$route.query.currency].rate
                  : props.rowData.amount -
                      getBuyingPrice(props.rowData.subscription) -
                      props.rowData.fee
              ).toFixed(2) | numberWithCommas
            }}
            ({{
              parseFloat(
                ((props.rowData.amount -
                  getBuyingPrice(props.rowData.subscription) -
                  props.rowData.fee) /
                  props.rowData.amount) *
                  100
              ).toFixed(2)
            }}%)
          </template>

          <template slot="actions" slot-scope="props">
            <router-link :to="{ path: `/payments/${props.rowData.id}` }"
              >View</router-link
            >
          </template>
        </vuetable>
        <vuetable-pagination
          v-show="paginationData.total"
          :css="table_css.pagination"
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        ></vuetable-pagination>
        <div class="text-center" v-if="rowCount <= 0 || rowCount === null">
          <slot name="empty"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuetableCss from "@/helpers/vuetable_styling.js";
import FilterDropdown from "./Filter";
import getPaginationData from "@/helpers/pagination";
import VuetablePagination from "@/components/VueTablePagination";
import { toLocal } from "@/helpers/date";
import { optional, getMargin, hasRole } from "@/helpers/global";
import moment from "moment";

export default {
  title: "Payments",
  components: { FilterDropdown, VuetablePagination },
  data() {
    return {
      rowCount: null,
      visibleFilter: false,
      table_css: VuetableCss,
      columns: [
        {
          name: "id",
          title: "ID",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:reference",
          title: "Reference",
          cvisible: true,
          active: true,
        },
        // {
        //   name: "last4",
        //   title: "Channel",
        //   cvisible: true,
        //   active: true,
        //   callback: (s) => {
        //     let color = s ? "green" : "red";
        //     return `<span class="bg-${color}-100 border border-${color}-400 text-${color}-700 px-4 py-3 rounded relative">${
        //       s ? "Card" : "Transfer"
        //     }</span>`;
        //   },
        // },
        {
          name: "type",
          title: "Type",
          cvisible: true,
          active: true,
          callback: (s) => {
            let color =
              s === "new" ? "yellow" : s === "extension" ? "green" : "blue";
            return `<span class="bg-${color}-100 border border-${color}-400 text-${color}-700 px-4 py-3 rounded relative">${s || 'card linking'}</span>`;
          },
        },
        {
          name: "__slot:user",
          title: "User",
          sortField: "user_id",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:amount",
          title: "Amount",
          sortField: "amount",
          cvisible: true,
          active: true,
        },
        // {
        //   name: "__slot:fee",
        //   title: "Fee",
        //   sortField: "fee",
        //   cvisible: true,
        //   active: true,
        // },
        // {
        //   name: "__slot:amountPerUnit",
        //   title: "Per Unit",
        //   cvisible: true,
        //   active: true,
        // },
        {
          name: "subscription.months",
          title: "Months",
          cvisible: true,
          active: true,
        },
        // {
        //   name: "__slot:amountDue",
        //   title: "Amount Due",
        //   cvisible: true,
        //   active: true,
        // },
        // {
        //   name: "__slot:margin",
        //   title: "Margin",
        //   cvisible: true,
        //   active: true,
        // },
        // {
        //   name: "__slot:discount",
        //   title: "Discount",
        //   cvisible: true,
        //   active: true,
        // },
        {
          name: "__slot:subscription",
          title: "Subscription",
          sortField: "subscription_id",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:service",
          title: "Service",
          cvisible: true,
          active: true,
        },
        {
          name: "__slot:status",
          title: "Status",
          cvisible: true,
          active: true,
          callback: (s) => {
            let color = s === "successful" ? "green" : "red";
            return `<span class="bg-${color}-100 border border-${color}-400 text-${color}-700 px-4 py-3 rounded relative">${s}</span>`;
          },
        },
        {
          name: "created_at",
          title: "Created At",
          cvisible: true,
          active: true,
          sortField: "created_at",
          callback: (s) => {
            return s ? toLocal(s).format("lll") : "N/A";
          },
        },

        {
          name: "__slot:actions",
          title: "Actions",
          active: true,
          cvisible: false,
        },
      ],
      moreParams: {
        per_page: 100,
        page: 1,
        service_id: [],
      },
      paginationData: {},
      api: `${this.$baseApi}/admin/payments?include=subscription,currency,user`,
      margin: 0,
      totalAmount: 0,
      marginPerMonth: 0,
      currency: null,
      daysAgo: null,
    };
  },
  methods: {
    toLocal,
    getPaginationData,
    optional,
    getMargin,
    hasRole,
    onPaginationData(paginationData) {
      this.paginationData = getPaginationData(paginationData, this.moreParams);
      this.$refs.pagination.setPaginationData(this.paginationData);
    },
    goto(e) {
      this.$router.push({ path: `/payments/${e.id}` });
    },
    loadTable() {
      let d = this.$refs.vuetable.tableData;
      d && this.calculateMargin(d);
      this.rowCount = d && d.length;
    },
    setFilters(d) {
      for (let [key, value] of Object.entries(this.moreParams)) {
        if (typeof value === "undefined") {
          delete this.moreParams[key];
        }

        let data;
        switch (key) {
          case "service":
            data = value ? value.map((s) => s.id) : [];
            this.moreParams[`service_id`] = [
              ...new Set([...this.moreParams[`service_id`], ...data]),
            ];
            // delete this.moreParams[key];
            break;

          case "created_at_from":
          case "created_at_to":
            if (value && value.split("T").length > 1)
              this.moreParams[key] = value;
            break;
          default:
            this.moreParams[key] = value;
            break;
        }
      }
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    clearFilters() {
      this.moreParams = {
        per_page: 100,
        service_id: [],
        page: 1,
      };
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.rowCount = sm.$refs.vuetable.tableData;
        sm.loadTable();
      });
    },
    onChangePage(page) {
      this.moreParams.page = page;
      this.setFilters();
    },
    refreshTable() {
      this.$refs.vuetable.refresh();
    },
    showVisibleFilter(d) {
      this.visibleFilter = d;
    },
    removeColumnFromTable(data) {
      this.columns.find((s) => s.name === data.name).active = data.val;
      let sm = this;
      this.$nextTick(() => {
        sm.$refs.vuetable.refresh();
        sm.$refs.vuetable.normalizeFields();
      });
    },
    getAmountByPlanType(subscription) {
      if (!subscription || !subscription.service) {
        return 0;
      }
      return (
        (subscription.plan === "shared"
          ? subscription.service.price_shared
          : subscription.plan === "family"
          ? subscription.service.price_family
          : subscription.service.price_personal) ||
        subscription.amount / subscription.months
      );
    },
    getBuyingPrice(subscription) {
      if (!subscription || !subscription.service) {
        return 0;
      }
      let subAmount;
      if (optional(subscription, "service.meta.price")) {
        subAmount =
          subscription.service.meta.price / subscription.service.meta.limit;
      } else {
        subAmount =
          subscription.amount >= 2000
            ? subscription.amount - 300
            : subscription.amount - 200;
      }
      return subAmount * subscription.months;
    },
    calculateMargin(payments) {
      this.margin = 0;
      this.totalAmount = 0;
      this.marginPerMonth = 0;
      for (let payment of payments) {
        if (payment.status === "refunded" || !payment.subscription) {
          continue;
        }
        if (!this.currency) {
          this.currency = payment.currency;
        }
        this.totalAmount += payment.amount;
        this.margin +=
          payment.amount -
          this.getBuyingPrice(payment.subscription) -
          payment.fee;
        this.marginPerMonth +=
          payment.amount / payment.subscription.months -
          this.getBuyingPrice(payment.subscription) /
            payment.subscription.months -
          payment.fee;
      }
    },
  },
  computed: {
    currencies() {
      return this.$store.getters["global/currencies"];
    },
    rates() {
      return this.$store.getters["global/rates"];
    },
    launchDate() {
      return this.$store.getters["global/launchDate"];
    },
  },
  created() {
    let moreParams = this.moreParams;
    this.moreParams = { ...moreParams, ...this.$route.query };
    let given = moment(this.launchDate);
    var current = moment().startOf("day");

    //Difference in number of days
    this.daysAgo = Math.abs(moment.duration(given.diff(current)).asDays());

    Promise.all([
      this.currencies.length || this.$store.dispatch("global/getCurrencies"),
    ]).finally(() => {
      this.loaded = true;
    });
  },
};
</script>
<style scoped>
.stat {
  padding: 2px;
}
</style>