export default {
  SET_SUBSCRIPTION(state, array) {
    state.subscription = array;
  },
  SET_META(state, data) {
    state.meta = data;
  },
  SET_SUBSCRIPTIONS(state, obj) {
    state.subscriptions = obj;
  },
  SET_REPORT(state, obj) {
    state.report = obj;
  },
}
