import axios from 'axios';
axios.defaults.headers.common['app-id'] = process.env.VUE_APP_API_APP_ID
const API = `${process.env.VUE_APP_BASE_URI}/admin`;

export default {
    index({
        commit
    }, { include = '', per_page = 5, sort = '' }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/app_ids?sort=${sort}&per_page=${per_page}&include=${include}`;
            axios.get(url)
                .then(async ({
                    data
                }) => {
                    commit('SET_APP_IDS', data.data);
                    delete data.data;
                    commit('SET_META', data.meta);
                    resolve(data.data);
                })
                .catch((error) => {
                    reject(error.response)
                });
        });
    },
    show({
        commit
    }, { id, include = '' }) {
        return new Promise((resolve, reject) => {
            let url = `${API}/app_ids/${id}?include=${include}`;
            axios.get(url)
                .then(async ({
                    data: { data }
                }) => {
                    commit('SET_APP_IDS', data);
                    resolve(data);
                })
                .catch((error) => {
                    reject(error.response)
                });
        });
    },
    create({
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            let url = `${API}/app_ids`;
            axios.post(url, payload)
                .then( ({
                    data
                }) => {

                    resolve(data);
                })
                .catch((error) => {
                    reject(error.response)
                });
        });
    },
    update({
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            let url = `${API}/app_ids/${payload.id}`;
            axios.patch(url, payload)
                .then(({
                    data
                }) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error.response)
                });
        });
    },
    refresh({
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            let url = `${API}/app_ids/${payload.id}/refresh`;
            axios.patch(url)
                .then(({
                    data
                }) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error.response)
                });
        });
    },

    delete({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            let url = `${API}/app_ids/${id}`;
            axios.delete(url)
                .then( ({
                    data
                }) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error.response)
                });
        });
    },
}
