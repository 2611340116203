import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const global = {
  namespaced: true,
  state: {
    is_open: true,
    countries: [],
    states: [],
    state: [],
    cities: [],
    city: [],
    currencies: [],
    categories: [],
    statuses: [],
    verification_types: [],
    roles: [],
    banks: [],
    post_categories: [],
    verification_statuses: [
      { name: 'pending', id: "PENDING" },
      { name: 'approved', id: "APPROVED" },
      { name: 'denied', id: "DENIED" }
    ],
    active_statuses: [
      { id: true, name: "active" },
      { id: false, name: "inactive" }
    ],
    id_types: [

      { id: "NIN", name: "National Identity Number (NIN) Slip" },
      { id: "PASSPORT", name: "International Passport" },
      { id: "PVC", name: "Permanent Voters' Card" },
      { id: "DRIVERS_LICENSE", name: "Driver's License" },

    ],
    statistics: {},
    rates: {
      'EUR' : {rate: 859.717 , symbol: '€'},
      'USD' : {rate: 815.516, symbol: '$'},
      'GBP' : {rate: 988.8132, symbol: '£'},
      'NGN' : {rate: 1, symbol: '₦'},
    },
    months: {
      1: 'January',
      2: 'February',
      3: 'March',
      4: 'April',
      5: 'May',
      6: 'June',
      7: 'July',
      8: 'August',
      9: 'September',
      10: 'October',
      11: 'November',
      12: 'December'
    },
    launchDate: '2023-01-19',
  },
  actions,
  getters,
  mutations
}
