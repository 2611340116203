export default {
    countries: state => state.countries,
    states: state => state.states,
    currencies: state => state.currencies,
    categories: state => state.categories,
    is_open: state => state.is_open,
    verification_types: state => state.verification_types,
    roles: state => state.roles,
    banks: state => state.banks,
    verification_statuses: state => state.verification_statuses,
    active_statuses: state => state.active_statuses,
    id_types: state => state.id_types,
    rates: state => state.rates,
    months: state => state.months,
    launchDate: state => state.launchDate,
}
