<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-semibold">
      {{ `Create App ID` }}
    </h3>

    <div class="stat" v-if="response">
          <span
            class="bg-green-100 border border-green-400 text-green-700 px-1 py-1"
            >publicKey: {{response.publicKey}}
          </span> | 
          <span
            class="bg-green-100 border border-green-400 text-green-700 px-1 py-1"
            >encryptionKey: {{response.encryptionKey}}
          </span>
      </div>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md">
          <form @submit.prevent="update" enctype="multipart/form-data">
            <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">
              <div>
                <label class="text-gray-700" for="title"><b>Name : </b></label>
                <input
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-validate="'required|min:4|max:100'"
                  v-model="body.name"
                />
                <small
                  class="form-text text-danger"
                  v-show="errors.has('name')"
                  >{{ errors.first("name") }}</small
                >
              </div>
              <div>
                <label class="text-gray-700" for="title"><b>Email : </b></label>
                <input
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-validate="'required|email'"
                  v-model="body.email"
                />
                <small
                  class="form-text text-danger"
                  v-show="errors.has('email')"
                  >{{ errors.first("email") }}</small
                >
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Description : </b></label
                >
                <input
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-validate="'required'"
                  v-model="body.description"
                />
                <small
                  class="form-text text-danger"
                  v-show="errors.has('description')"
                  >{{ errors.first("description") }}</small
                >
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Domain : </b></label
                >
                <input
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-validate="'required'"
                  v-model="body.domain"
                />
                <small
                  class="form-text text-danger"
                  v-show="errors.has('domain')"
                  >{{ errors.first("domain") }}</small
                >
              </div>
              <div>
                <label class="text-gray-700" for="title"
                  ><b>Webhook URL : </b></label
                >
                <input
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-validate="'url'"
                  v-model="body.webhook"
                />
                <small
                  class="form-text text-danger"
                  v-show="errors.has('webhook')"
                  >{{ errors.first("webhook") }}</small
                >
              </div>
              <div class="bg-danger text-primary text-sm" v-if="key">
                {{ key }}
              </div>

              <div class="flex justify-end mt-4">
                <button
                  :disabled="loading || errors.any()"
                  @click="update()"
                  :class="`px-5 py-3 ml-2 bg-red-${
                    loading ? 200 : 800
                  } text-red-200 rounded-md hover:bg-red-700 focus:outline-none focus:bg-red-${
                    loading ? 100 : 700
                  }`"
                >
                  {{ loading ? "Generating" : "Create" }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { optional } from "@/helpers/global";
import { toLocal } from "@/helpers/date";
import { handleApiError } from "@/helpers/error";

export default {
  title: () => {
    return `Create | AppIds`;
  },
  data() {
    return {
      loaded: false,
      loading: false,
      body: {},
      response: null,
      key: null,
    };
  },
  methods: {
    toLocal,
    optional,
    update() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.loading = true;
          this.submit()
        }
      });
    },
    submit() {
      this.$store
        .dispatch("appIds/create", this.body)
        .then(({ message, data }) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
          this.response = data
        })
        .catch((err) => {
          handleApiError(this, err, `Failed to create appId`);
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {},
  created() {
    this.loaded = true;
  },
};
</script>
