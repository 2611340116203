import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const subscriptions = {
  namespaced: true,
  state: {
    subscriptions : [],
    subscription : {},
    meta : {},
    report: []
  },
  actions,
  getters,
  mutations
}
