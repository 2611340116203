<template>
  <div>
    <transition name="slide-fade">
      <div >
        <form class="d-form" @submit.prevent="save">
          <div class="grid grid-cols-7 mb-2 gap-3">
            <div >
              <span class="text-bold text-opacity-pale"> Search</span>
              <input
                type="text"
                v-model="params['search']"
                @input="params.search.length > 3 && debouce(params.search, () => {setFilters('search', params.search);})"
                name="search"
               class="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600"
                placeholder="Enter search param"
              >
            </div>

 <div >
              <span class="text-bold text-opacity-pale">Method</span>
              <multiselect
                    track-by="id"
                      return="id"

                      :multiple="true"
                      @input="setFilters('method',params['method'])"
                      v-model="params['method']"
                      name="method"
                      :options="['GET', 'POST', 'PATCH', 'DELETE']"

                      :show-labels="false"
              >

              </multiselect>
            </div>

            <div >
              <span class="text-bold text-opacity-pale">Status Code</span>
              <multiselect
                    track-by="id"
                      return="id"

                      :multiple="true"
                      @input="setFilters('status_code',params['status_code'])"
                      v-model="params['status_code']"
                      name="status_code"
                      :options="[200, 201, 400, 401, 403, 404, 422, 429, 500]"

                      :show-labels="false"
              >

              </multiselect>
            </div>


            <div >
              <span class="text-bold text-opacity-pale">Created At (From)</span>
              <input
              type="date"
                v-model="params['created_at_from']"
                @input="setFilters('created_at_from',params['created_at_from'])"
                class="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600"


              />
            </div>
               <div >
              <span class="text-bold text-opacity-pale">Created At (To)</span>
              <input
              type="date"
              class="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600"

                v-model="params['created_at_to']"
                @input="setFilters('created_at_to',params['created_at_to'])"
                name="created_at_to"
              />
            </div>

              <div >
              <span class="text-bold text-opacity-pale">Per Page</span>
               <multiselect
                      :close-on-select="false"
                @input="setFilters('per_page',params['per_page'])"
                v-model="params['per_page']"
                name="per_page"
                :options="[50, 100, 250, 500]"
                :show-labels="false"
              ></multiselect>
            </div>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["params", "currencies", "visibleFilter"],
   data() {
    return {
      timeoutQueue: []
    }
   },
  methods: {
    debouce(name, functionCall, timeout = 2) {
      this.timeoutQueue = this.timeoutQueue.filter(s => {

        clearTimeout(s);

        return false;
        });
      let timeoutId = setTimeout(functionCall(), timeout * 1000);
      this.timeoutQueue.push(timeoutId);
    },
    setFilters(name, value) {

      this.$emit("setFilters", {
         name,
        value
      });
    }
  }
};
</script>
