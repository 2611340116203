<template>
    <div class="container-fluid">
      <div class="table pt-2 pb-4 pr-3">
        <div class="table-header">
          <div class="grid">
            <div>
              <small class="text-opacity"
                >{{ paginationData.total }} total processing subscriptions</small
              >
            </div>
          </div>
          <br />
          <filter-dropdown
            @setFilters="setFilters"
            :params="moreParams"
            :type="'cancelled'"
          ></filter-dropdown>
          <button
            @click="clearFilters"
            :class="`px-2 py-3 bg-red-600 rounded-md text-white font-small tracking-wide hover:bg-red-200`"
          >
            Clear Filters
          </button>
        </div>
        <div
          class="align-middle inline-block shadow sm:rounded-lg border-b border-gray-200"
        >
          <vuetable
            ref="vuetable"
            :css="table_css.table"
            :api-url="api"
            :append-params="moreParams"
            :http-options="{
              headers: { Authorization: 'Bearer ' + $auth.token() },
            }"
            @vuetable:loaded="loadTable"
            @vuetable:cell-clicked="goto"
            :fields="columns.filter((s) => s.active === true)"
            data-path="data"
            pagination-path="meta"
            :no-data-template="
              rowCount === null ? 'Loading data' : 'No Data Available'
            "
            @vuetable:pagination-data="onPaginationData"
          >
            <template slot="sn" slot-scope="props">
              <router-link :to="{ path: `/subscriptions/${props.rowData.id}` }">{{
                props.rowIndex + 1
              }}</router-link>
            </template>
  
            <template slot="user" slot-scope="props">
              <router-link :to="{ path: `/users/${props.rowData.user_id}` }">
                <span class="text-red-500">
              {{`${props.rowData.user.first_name || ''} ${props.rowData.user.last_name || ''}`}}
              <br><small class="text-blue-500">{{
                props.rowData.user.email
              }}</small></span></router-link>
            </template>
            <template slot="payments" slot-scope="props">
              <router-link
                :to="{
                  path: `/payments`,
                  query: { subscription_id: props.rowData.id },
                }"
                >Payments</router-link
              >
            </template>
            <template slot="status" slot-scope="props">
              <small :class="`bg-${props.rowData.active ? 'green' : 'red'}-100 border border-${props.rowData.active ? 'green' : 'red'}-400 text-${props.rowData.active ? 'green' : 'red'}-700 px-4 py-3 rounded relative`">{{ props.rowData.status || (props.rowData.active  ? 'Active' : 'Inactive')}}</small>
            </template>
  
            <template slot="group" slot-scope="props">
              <router-link v-if="props.rowData.group" :to="{ path: `/groups/${props.rowData.group_id}` }">
                <span class="text-red-500">
              #{{props.rowData.group.id}}
              <br><small class="text-blue-500">{{props.rowData.group.email}}</small></span></router-link>
               <span v-else>N/A</span>
            </template>

             <template slot="prevGroup" slot-scope="props">
              <router-link v-if="props.rowData.meta.prev_group_id" :to="{ path: `/groups/${props.rowData.meta.prev_group_id}` }">
                <span class="text-red-500">
              #{{props.rowData.meta.prev_group_id}}
              </span></router-link>
               <span v-else>N/A</span>
            </template>
  
            <template slot="service" slot-scope="props">
              <router-link
                v-if="props.rowData.service"
                :to="{ path: `/services/${props.rowData.service_id}` }"
                >{{
                  props.rowData.service && props.rowData.service.name
                }}</router-link
              >
            </template>
  
            <template slot="amount" slot-scope="props">
              {{
                $route.query.currency
                  ? rates[$route.query.currency].symbol
                  : props.rowData.service &&
                    props.rowData.service.currency &&
                    props.rowData.service.currency.symbol
              }}{{
                parseFloat(
                  $route.query.currency
                    ? props.rowData.amount / rates[$route.query.currency].rate
                    : props.rowData.amount
                ).toFixed(2) | numberWithCommas
              }}
            </template>
            <template slot="cumulative_amount" slot-scope="props">
              {{
                $route.query.currency
                  ? rates[$route.query.currency].symbol
                  : props.rowData.service &&
                    props.rowData.service.currency &&
                    props.rowData.service.currency.symbol
              }}{{
                parseFloat(
                  $route.query.currency
                    ? props.rowData.cumulative_amount /
                        rates[$route.query.currency].rate
                    : props.rowData.cumulative_amount
                ).toFixed(2) | numberWithCommas
              }}
            </template>
  
            <template slot="actions" slot-scope="props">
              <div class="dropdown block relative">
                <button
                  class="bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center"
                >
                  <span class="mr-1">Actions</span>
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </button>
                <ul class="dropdown-menu hidden text-gray-700 pt-1">
                  <li class="">
                    <router-link
                      :class="`rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap`"
                      :to="{ path: `/subscriptions/${props.rowData.id}` }"
                      >View</router-link
                    >
                  </li>
  
                  <li class="">
                    <router-link
                      :class="`rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap`"
                      :to="{
                        path: `/payments`,
                        query: { subscription_id: props.rowData.id },
                      }"
                      >Payments</router-link
                    >
                  </li>
                </ul>
              </div>
            </template>
          </vuetable>
          <vuetable-pagination
            v-show="paginationData.total"
            :css="table_css.pagination"
            ref="pagination"
            @vuetable-pagination:change-page="onChangePage"
          ></vuetable-pagination>
          <div class="text-center" v-if="rowCount <= 0 || rowCount === null">
            <slot name="empty"></slot>
          </div>
        </div>
      </div>
      <modal
        v-if="deleteAction"
        :open="deleteAction"
        @performAction="deleteItem"
        :model="model"
        :title="title"
        :description="description"
      ></modal>
    </div>
  </template>
      
      <script>
  import VuetableCss from "@/helpers/vuetable_styling.js";
  import FilterDropdown from "./Filter";
  import getPaginationData from "@/helpers/pagination";
  import VuetablePagination from "@/components/VueTablePagination";
  import Modal from "@/components/Modal";
  import { toLocal } from "@/helpers/date";
  
  export default {
    title: "Processing | Subscriptions",
    components: { FilterDropdown, VuetablePagination, Modal },
    data() {
      return {
        title: null,
        description: null,
        model: null,
        deleteAction: false,
        rowCount: null,
        visibleFilter: false,
        table_css: VuetableCss,
        columns: [
          {
            name: "id",
            title: "ID",
            cvisible: true,
            sortField: "id",
            active: true,
          },
          {
            name: "reference",
            title: "Reference",
            cvisible: true,
            active: true,
          },
          {
            name: "__slot:service",
            title: "Service",
  
            cvisible: true,
            active: true,
          },
          {
            name: "__slot:user",
            title: "User",
  
            cvisible: true,
            active: true,
          },
          {
            name: "__slot:status",
            title: "Status",
            cvisible: true,
            active: true,
            sortField: "active",
          },
          {
            name: "reminded",
            active: true,
            sortField: "reminded",
            title: "Reminders",
          },
          {
            name: "__slot:group",
            active: true,
            title: "Group",
          },
             {
          name: "__slot:prevGroup",
          active: true,
          title: "Previous Group",
        },
          {
            name: "__slot:payments",
            active: true,
            title: "Payments",
          },
          {
            name: "updated_at",
            title: "Updated At",
            cvisible: true,
            active: true,
            sortField: "updated_at",
            callback: (s) => {
              return s ? toLocal(s).format("lll") : "N/A";
            },
          },
          {
            name: "__slot:actions",
            title: "Actions",
            active: true,
            cvisible: false,
          },
        ],
        moreParams: {
          per_page: 100,
          service_id: [],
          status: 'processing',
          sort: "updated_at|asc",
          page: 1,
        },
        paginationData: {},
        api: `${this.$baseApi}/admin/subscriptions`,
      };
    },
    methods: {
      toLocal,
      getPaginationData,
      onPaginationData(paginationData) {
        this.paginationData = getPaginationData(paginationData, this.moreParams);
        this.$refs.pagination.setPaginationData(this.paginationData);
      },
      goto(e) {
        this.$router.push({ path: `/subscriptions/${e.id}` });
      },
      loadTable() {
        let d = this.$refs.vuetable.tableData;
        this.rowCount = d && d.length;
      },
      setFilters(data) {
        for (let [key, value] of Object.entries(this.moreParams)) {
          if (typeof value === "undefined") {
            delete this.moreParams[key];
          }
  
          let data, newData;
          switch (key) {
            case "service":
              data = value ? value.map((s) => s.id) : [];
              this.moreParams[`service_id`] = [
                ...new Set([...this.moreParams[`service_id`], ...data]),
              ];
              // delete this.moreParams[key];
              break;
  
            case "created_at_from":
            case "created_at_to":
            case "expires_at_from":
            case "expires_at_to":
              if (value && value.split("T").length > 1)
                this.moreParams[key] = value;
              break;
            default:
              this.moreParams[key] = value;
              break;
          }
        }
  
        let sm = this;
        this.$nextTick(() => {
          sm.$refs.vuetable.refresh();
          sm.rowCount = sm.$refs.vuetable.tableData;
          sm.loadTable();
        });
      },
      clearFilters() {
        this.moreParams = {
          per_page: 100,
          service_id: [],
          page: 1,
          sort: "expires_at|asc",
          status: 'processing',
        };
        let sm = this;
        this.$nextTick(() => {
          sm.$refs.vuetable.refresh();
          sm.rowCount = sm.$refs.vuetable.tableData;
          sm.loadTable();
        });
      },
      onChangePage(page) {
        this.moreParams.page = page;
        this.setFilters();
      },
      refreshTable() {
        this.$refs.vuetable.refresh();
      },
      showVisibleFilter(d) {
        this.visibleFilter = d;
      },
      removeColumnFromTable(data) {
        this.columns.find((s) => s.name === data.name).active = data.val;
        let sm = this;
        this.$nextTick(() => {
          sm.$refs.vuetable.refresh();
          sm.$refs.vuetable.normalizeFields();
        });
      },
    },
    computed: {
      rates() {
        return this.$store.getters["global/rates"];
      },
    },
    created() {
      let moreParams = this.moreParams;;
      moreParams.status = 'processing';
      this.moreParams = { ...moreParams, ...this.$route.query };
  
      this.loaded = true;
    },
  };
  </script>
      