<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-semibold">
      {{ `Subscription #${subscription.id}` }}
    </h3>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md">
          <form @submit.prevent="update">
            <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">
              <div>
                <label class="text-gray-700" for="title"
                  ><b>Reference : </b></label
                >
                <span class="">{{ subscription.reference }} </span>
              </div>
              <div>
                <label class="text-gray-700" for="title"
                  ><b>Service : </b></label
                >
                <router-link
                  :to="{ path: `/services/${subscription.service_id}` }"
                  >{{
                    subscription.service && subscription.service.name
                  }}</router-link
                >
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Group : </b></label>
                <router-link
                  v-if="subscription.group_id"
                  :to="{ path: `/groups/${subscription.group_id}` }"
                  >{{ subscription.group_id }}</router-link
                >
                <span v-else>N/A</span>
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>User : </b></label>
                <router-link
                  v-if="subscription.user"
                  :to="{ path: `/users/${subscription.user_id}` }"
                >
                  <span class="text-red-500">
                    {{
                      `${subscription.user.first_name || ''} ${subscription.user.last_name || ''}`
                    }}
                    <small class="text-blue-500"> ({{
                      subscription.user.email
                    }})</small></span
                  ></router-link
                >
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Type : </b></label>
                <span class=""
                  >{{
                    subscription.subplan || subscription.plan
                  }}
                </span>
              </div>
              <div v-if="!optional(subscription, 'service.subplans', []).length">
                <label class="text-gray-700" for="title"
                  ><b>Auto-renew : </b></label
                >
                <span class=""
                  >{{ subscription.recurring ? "On" : "Off" }}
                </span>
              </div>
              <div>
                <label class="text-gray-700" for="title"
                  ><b>Amount : </b></label
                >
                <span class="">
                  {{
                    subscription.service &&
                    subscription.service.currency &&
                    subscription.service.currency.symbol
                  }}{{ subscription.amount }}
                </span>
              </div>
              <div v-if="!optional(subscription, 'service.subplans', []).length">
                <label class="text-gray-700" for="title"
                  ><b>Duration : </b></label
                >
                <span class="">{{ subscription.months }} </span>
              </div>
              <div v-if="!optional(subscription, 'service.subplans', []).length">
                <label class="text-gray-700" for="title"
                  ><b>Renewals : </b></label
                >
                {{ subscription.renewals }}
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Creation Date : </b></label
                >
                <span class="">{{
                  toLocal(subscription.created_at).format("lll")
                }}</span>
              </div>

              <div v-if="subscription.cancelled_at">
                <label class="text-gray-700" for="title"
                  ><b>Cancelled At : </b></label
                >
                <span class=""
                  >{{ toLocal(subscription.cancelled_at).format("lll") }}
                  <small
                    >by
                    {{
                      subscription.cancelled_by_user ? "user" : "system"
                    }}</small
                  ></span
                >
              </div>


              <div v-if="subscription.cancel_reason">
                <label class="text-gray-700" for="title"
                  ><b>Cancel Reason: </b></label
                >
                <span class="">{{ subscription.cancel_reason }}</span>
              </div>

              <div v-if="subscription.refund_requested_at && subscription.status !== 'refunded'">
                <label class="text-gray-700" for="title"
                  ><b>Refund Requested At : </b></label
                >
                <span class=""
                  >{{ toLocal(subscription.refund_requested_at).format("lll") }}
                  </span
                >
              </div>

              <div v-if="!optional(subscription, 'service.subplans', []).length">
                <label class="text-gray-700" for="title"
                  ><b>Expires At : </b></label
                >
                <span class="">{{
                 subscription.expires_at ? toLocal(subscription.expires_at).format("lll") : 'N/A'
                }}</span>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Payments : </b></label
                >
                <router-link :to="{ path: `/payments`, query: { subscription_id: subscription.id}}">View Payments</router-link>
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Active : </b></label
                >
                <input name="active" type="checkbox" v-model="body.active" />
              </div>

              <div
                v-if="
                  optional(subscription, 'service.subplans', []).length || optional(subscription, 'service.require_email')
                "
              >
                <label class="text-gray-700" for="service_email"
                  ><b
                    >{{ subscription.service && subscription.service.name }}
                    {{ optional(subscription, 'service.subplans', []).length ? 'PlayerID' : 'Email' }}:
                  </b></label
                >
                <input
                  name="service_email"
                  :type="optional(subscription, 'service.subplans', []).length ? 'text': 'email'"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-model="body.service_email"
                />
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Update Group : </b
                  ><span v-if="subscription.__eligible_group_id"
                    >Eligible Group
                    <router-link
                      :to="{
                        path: `/groups/${subscription.__eligible_group_id}`,
                      }"
                      >#{{ subscription.__eligible_group_id }}</router-link
                    ></span
                  ></label
                >
                <input
                  name="group_id"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-model="body.group_id"
                />
              </div>

                <div v-if="optional(subscription, 'service.subplans', []).length">
                <label class="text-gray-700" for="title"
                  ><b>Update Code : </b
                  ></label
                >
                <wysiwyg
                    class="
                      form-input
                      w-full
                      mt-2
                      rounded-md
                      focus:border-indigo-600
                    "
                    v-model="body.pin"
                  />
              </div>

               <div>
                <label class="text-gray-700" for="title"><b>Status : </b></label>
                <select
                  name="status"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-model="body.status"
                >
                <option value="active">Active</option>
                <option value="suspended">Suspended</option>
                 <option value="inactive">Inactive</option>
                   <option value="refunded">Refunded</option>
                 <option value="cancelled">Cancelled</option>
                </select>
              </div>

               <div v-if="subscription.active">
                <label class="text-gray-700" for="title"><b>Reason : </b></label>
                <textarea
                  name="reason"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-model="body.reason"
                ></textarea>
              </div>

              <div
                class="flex justify-end mt-4"
               
              >
                <button
                 v-if="
                  body.group_id != subscription.group_id ||
                  body.pin != subscription.pin ||
                  body.service_email != subscription.service_email ||
                  body.active != subscription.active
                "
                  :disabled="loading"
                  @click="update"
                  :class="`px-4 py-2 bg-gray-${
                    loading ? 200 : 800
                  } text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-${
                    loading ? 100 : 700
                  }`"
                >
                  {{ "Update Group" }}
                </button>

                 <button
                  :disabled="loading"
                  @click="updateStatus"
                  :class="`mx-2 px-4 py-2 bg-green-${
                    loading ? 200 : 800
                  } text-green-200 rounded-md hover:bg-green-700 focus:outline-none focus:bg-green-${
                    loading ? 100 : 700
                  }`"
                >
                  {{ "Update Subscription" }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { handleApiError } from "@/helpers/error";
import { toLocal } from "@/helpers/date";
import { optional } from "@/helpers/global";

export default {
  title: () => {
    return `Subscription #${window.location.href
      .split("/")
      .pop()} | Subscriptions`;
  },
  data() {
    return {
      loaded: false,
      loading: false,
      body: {},
    };
  },
  methods: {
    toLocal,
    optional,
    update() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    submit() {
      this.$store
        .dispatch("subscriptions/update", this.body)
        .then(({ message }) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to update subscription");
        })
        .finally(() => (this.loading = false));
    },

    updateStatus() {
      let r = confirm(`Are you sure to update subscription #${this.subscription.id} status to [${this.body.status}] with reason (${this.body.reason})?`)
      if(!r) return
      this.loading = true
      this.$store
        .dispatch("subscriptions/cancel", {
          id: this.subscription.id,
          reason: this.body.reason,
          status: this.body.status,
        })
        .then(({ message }) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to cancel subscription");
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    subscription() {
      return this.$store.getters["subscriptions/subscription"];
    },
  },
  created() {
    this.$store
      .dispatch("subscriptions/getSingle", {
        id: this.$route.params.id,
        include: "user,service",
      })
      .finally(() => {
        this.body = {
          id: this.subscription.id,
          status: this.subscription.status,
          reason: this.subscription.cancel_reason,
          pin: this.subscription.pin,
          group_id: this.subscription.group_id,
          active: this.subscription.active,
          service_email: this.subscription.service_email,
        };
        this.loaded = true;
      });
  },
};
</script>
