<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-semibold">
      {{ `Admin #${user.id}` }}
    </h3>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md">
          <form @submit.prevent="update">
            <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">

              <div>
                <label class="text-gray-700" for="title"><b>Email : </b></label>
                <input
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-validate="'required|email'"
                  v-model="user.email"
                />
              </div>
              <div>
                <label class="text-gray-700" for="title"><b>Name : </b></label>
                <span class="">{{
                  `${user.first_name} ${user.middle_name || ''} ${user.last_name}`
                }}</span>
              </div>

              <div v-if="user.photo">
                <label class="text-gray-700" for="title"><b>Image : </b></label>
                <img :src="user.photo" alt="User Image" />
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Roles : </b></label>
                <multiselect
                  track-by="id"
                  return="id"
                  label="name"
                  :multiple="true"
                  v-model="user.roles"
                  name="role"
                  :options="roles"
                  :show-labels="false"
                ></multiselect>

                <small
                  class="form-text text-danger"
                  v-show="errors.has('roles')"
                  >{{ errors.first("roles") }}</small
                >
              </div>

              <div>
                <label class="text-gray-700" for="title"
                  ><b>Password : </b></label
                >
                <input
                  ref="password"
                  name="password"
                  type="password"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"

                  v-model="user.password"
                />
                <small class="text-gray-200" v-show="errors.has('password')">{{
                  errors.first("password")
                }}</small>
              </div>

              <div v-if="user.password">
                <label class="text-gray-700" for="title"
                  ><b>Confirm Password : </b></label
                >
                <input
                  name="confirm_password"
                  type="password"
                  class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
                  v-validate="'required|min:8|confirmed:password'"
                  v-model="user.confirm_password"
                />
                <small
                  class="form-text text-danger"
                  v-show="errors.has('confirm_password')"
                  >{{ errors.first("confirm_password") }}</small
                >
              </div>

              <div>
                <label class="text-gray-700" for="title"><b>Creation Date : </b></label>
                <span class="">{{ toLocal(user.created_at).format('lll') }}</span>
              </div>

              <div class="flex justify-end mt-4" v-if="$auth.user().id != user.id">
                <button
                  :disabled="loading"
                  @click="update"
                  :class="`px-4 py-2 bg-gray-${loading ? 200 : 800} text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-${loading ? 100 : 700}`"
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { handleApiError } from "@/helpers/error";
import {toLocal} from "@/helpers/date";

export default {
  title: "Show Admin | Admins",
  data() {
    return {
      loaded: false,
      loading: false,
    };
  },
  methods: {
    toLocal,
    update() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.loading = true;
          this.submit();
        }
      });
    },
    submit() {
      let data = {};

      Object.assign(data, this.user);
      data.roles = data.roles.map((s) => s.id);

      this.$store
        .dispatch("admins/update", data)
        .then(() => {
          this.$toast.success(`Admin roles have been updated`, {
            position: "top-right",
            duration: 3000,
          });
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to update admin");
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    user() {
      return this.$store.getters["admins/admin"];
    },
    roles() {
      return this.$store.getters["global/roles"];
    },
  },
  created() {
    Promise.all([
      this.roles.length || this.$store.dispatch("global/getRoles"),
      this.$store.dispatch("admins/getSingle", {
        id: this.$route.params.id,
        include: "roles,permissions",
      }),
    ]).finally(() => {
      this.loaded = true;
    });
  },
};
</script>
