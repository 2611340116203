import axios from 'axios';

const API = `${process.env.VUE_APP_BASE_URI}/admin`;

export default {
    index({
        commit
    }, obj) {
        return new Promise((resolve, reject) => {
            let url = `${API}/reports?${new URLSearchParams(Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))).toString()}`;
            axios.get(url)
                .then(async ({
                    data
                }) => {

                    commit('SET_REPORT', data.data);
                    resolve(data.data);
                })
                .catch((error) => {
                    reject(error.response)
                });
        });
    },
}
