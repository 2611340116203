import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const admins = {
  namespaced: true,
  state: {
    admins : [],
    admin : {},
    meta : {}
  },
  actions,
  getters,
  mutations
}
