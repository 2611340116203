import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const expenses = {
  namespaced: true,
  state: {
    expenses : [],
    expense : {},
    meta : {},
    report: {},
  },
  actions,
  getters,
  mutations
}
