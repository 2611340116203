<template>
  <div :class="{ blur: !loaded }">
    <h3 class="text-gray-700 text-3xl font-semibold">
      {{ `Payment #${payment.reference}` }}
    </h3>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md">
          <div class="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-4">
            <div>
              <label class="text-gray-700" for="title"><b>User : </b></label>
              <router-link :to="{ path: `/users/${payment.user_id}` }">{{
                payment.user && payment.user.first_name || ''
              }}</router-link>
            </div>

            <div v-if="payment.type !== 'credit'">
              <label class="text-gray-700" for="title"
                ><b>Subscription : </b></label
              >
              <router-link v-if="payment.subscription_id"
                :to="{ path: `/subscriptions/${payment.subscription_id}` }"
                >{{
                  payment.subscription && payment.subscription.reference
                }}</router-link
              >
            </div>

            <div>
              <label class="text-gray-700" for="title"
                ><b>Description : </b></label
              >
              <span class="">{{ payment.description }}</span>
            </div>

            <div>
              <label class="text-gray-700" for="title"
                ><b>Type : </b></label
              >
              <span class="">{{ payment.type }}</span>
            </div>

            <div>
              <label class="text-gray-700" for="title"
                ><b>Status : </b></label
              >
              <span :class="`bg-${payment.status === 'successful' ? 'green' : 'red'}-100 border border-${payment.status === 'successful' ? 'green' : 'red'}-400 text-${payment.status === 'successful' ? 'green' : 'red'}-700 px-4 py-3 rounded relative`">{{ payment.status }}</span><small v-if="optional(payment, 'meta.refund.reason')">: <i>{{ optional(payment, 'meta.refund.reason') }}</i></small>
            </div>

            <div v-if="optional(payment, 'meta.refund')">
              <label class="text-gray-700" for="title"
                ><b>Refund Status : </b></label
              >
              <span :class="`bg-${payment.status === 'pending' ? 'green' : 'red'}-100 border border-${payment.status === 'pending' ? 'green' : 'red'}-400 text-${payment.status === 'pending' ? 'green' : 'red'}-700 px-4 py-3 rounded relative`">{{ payment.meta.refund.status }}</span><small v-if="optional(payment, 'meta.refund.message')">: <i>{{ optional(payment, 'meta.refund.message') }}</i></small>
            </div>

            <div>
              <label class="text-gray-700" for="title"
                ><b
                  >{{
                    payment.type == "withdrawal"
                      ? "Amount Paid Out"
                      : "Amount Received"
                  }}:
                </b></label
              >
              <span class=""
                >{{ payment.currency | optional("symbol", "") }}
                {{ parseFloat(payment.amount).toFixed(2) | numberWithCommas }}
              </span>
            </div>
            <div>
              <label class="text-gray-700" for="title"><b>Fee : </b></label>
              <span class=""
                >{{ payment.currency | optional("symbol", "") }}
                {{ payment.fee | numberWithCommas }}
              </span>
            </div>

            <div>
              <label class="text-gray-700" for="title"><b>Date : </b></label>
              <span class="">{{
                toLocal(payment.created_at).format("lll")
              }}</span>
            </div>

          <div v-if="payment.type !== 'credit'">
            <label class="text-gray-700" for="title"
              ><b>Refund Amount : </b></label
            >
            <input
              type="number"
              required
              min="100"
              :max="payment.amount"
              class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
              v-model="form.amount"
            />
          </div>

          <div v-if="payment.type !== 'credit'">
            <label class="text-gray-700" for="title"
              ><b>Refund reason : </b></label
            >
            <textarea
              type="text"
              class="form-input w-full mt-2 rounded-md focus:border-indigo-600"
              v-model="form.reason"
            ></textarea>
          </div>
          </div>

          <div
            class="flex justify-end mt-4"
            
          >
            <button
            v-if="!(payment.type === 'credit' && payment.status === 'processing') && payment.status !== 'refunded'"
              :disabled="loading"
              @click="refund"
              :class="`px-8 py-2 mr-4 bg-gray-${
                loading ? 200 : 800
              } text-gray-200 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-${
                loading ? 100 : 700
              }`"
            >
              {{ loading ? "Refunding..." : "Issue Refund" }}
            </button>

            <button
            v-if="payment.type === 'credit' && payment.status === 'processing'"
              :disabled="loading"
              @click="approve"
              :class="`px-8 py-2 bg-red-${
                loading ? 200 : 800
              } text-red-200 rounded-md hover:bg-red-700 focus:outline-none focus:bg-red-${
                loading ? 100 : 700
              }`"
            >
              {{ loading ? "Approving..." : "Approve" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { optional, getMargin } from "@/helpers/global";
import { toLocal } from "@/helpers/date";
import { handleApiError } from "@/helpers/error";

export default {
  title:() => {
     return `Payment #${window.location.href.split('/').pop()} | Payments`
  },
  data() {
    return {
      loaded: false,
      loading: false,
      form: {
        amount: 0,
      },
    };
  },
  methods: {
    toLocal,
    optional,
    getMargin,
    refund() {
      let r = confirm(
        `Are you sure you want to refund payment #${this.payment.id}? This will deactivate the attached subscription and mark the payment as refunded`
      );
      if (!r) return;
      this.loading = true;
      this.$store
        .dispatch("payments/refund", {
          id: this.payment.id,
          amount: this.form.amount,
          reason: this.form.reason,
        })
        .then(({ message }) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to trigger refund");
        })
        .finally(() => (this.loading = false));
    },
    approve() {
      let r = confirm(
        `Are you sure you want to approve payment #${this.payment.id}? This will initiate transfer to the user's bank`
      );
      if (!r) return;
      this.loading = true;
      this.$store
        .dispatch("payments/approve", {
          id: this.payment.id,
        })
        .then(({ message }) => {
          this.$toast.success(message, {
            position: "top-right",
            duration: 3000,
          });
        })
        .catch((err) => {
          handleApiError(this, err, "Failed to trigger transfer");
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    payment() {
      return this.$store.getters["payments/payment"];
    },
  },
  created() {
    Promise.all([
      this.$store.dispatch("payments/getSingle", {
        id: this.$route.params.id,
        include: "user,subscription",
      }),
    ]).finally(() => {
      this.loaded = true;
      Object.assign(this.form, this.payment);
      this.form.reason = optional(this.payment, 'meta.refund.reason')
    });
  },
};
</script>
