export default {
  SET_PAYMENTS(state, array) {
    state.payments = array;
  },
  SET_META(state, data) {
    state.meta = data;
  },
  SET_PAYMENT(state, obj) {
    state.payment = obj;
  },
  SET_REPORT(state, obj) {
    state.report = obj;
  },
}
