export function handleApiError(vm, error, message = 'Failed to perform action. Please try again.') {
    message = error && error.data && error.data.message ? error.data.message : message;
    if (error && error.data && error.status === 422) {
      message = 'There are some validation errors in your input. Please check and fix.'
      let errors = (error.data && error.data.errors) || {};
      Object.keys(errors).forEach(function (field) {
        let msg = `${field}: ${errors[field].join(' ')}`
        vm.$toast.error(msg, {
          position: "top-right",
          duration: 2000
        });
      }, vm);
    }
    vm.$toast.error(message, {
      position: "top-right",
      duration: 3000
    });
}
