<template>
  <div :class="{ blur: !loaded }">
    <div class="p2">
      <h3 class="text-gray-700 text-3xl font-semibold">Summary Report</h3>
      <h5>
        {{ toLocal(moreParams.created_at_from).format("ll") }} to
        {{ toLocal(moreParams.created_at_to).format("ll") }}
      </h5>
      <h6>
        <small
          ><i>Generated at {{ toLocal().format("lll") }}</i></small
        >
      </h6>
    </div>

    <div>
      <transition name="slide-fade">
        <div>
          <form class="d-form" @submit.prevent>
            <div class="grid grid-cols-6">
              <div>
                <span class="text-bold text-opacity-pale">Group By</span>
                <multiselect
                  v-model="moreParams['group_by']"
                  name="group_by"
                  :options="[
                    'YEAR(created_at)',
                    'MONTH(created_at)',
                    'WEEK(created_at)',
                    'DATE(created_at)',
                  ]"
                  :show-labels="false"
                ></multiselect>
              </div>

              <div>
                <span class="text-bold text-opacity-pale">Year</span>
                <multiselect
                  :multiple="false"
                  v-model="moreParams['year']"
                  name="year"
                  :options="[2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]"
                  :show-labels="false"
                ></multiselect>
              </div>

              <div>
                <span class="text-bold text-opacity-pale">Month</span>
                <multiselect
                  :multiple="false"
                  v-model="moreParams['month']"
                  name="month"
                  :options="Object.values(months)"
                  :show-labels="false"
                ></multiselect>
              </div>
              <div>
                <span class="text-bold text-opacity-pale">Currency</span>
                <multiselect
                  :multiple="false"
                  name="currency"
                  @input="setChartData(apiData)"
                  v-model="currency"
                  :options="['NGN', 'USD', 'EUR']"
                  :show-labels="false"
                ></multiselect>
              </div>

              <div>
                <span class="text-bold text-opacity-pale">Type</span>
                <multiselect
                  :multiple="false"
                  @input="setChartData(apiData)"
                  v-model="type"
                  :options="['count', 'amount']"
                  :show-labels="false"
                ></multiselect>
              </div>

              <div>
                <span class="text-bold text-opacity-pale"
                  >Created At (From)</span
                >
                <input
                  type="date"
                  v-model="moreParams['created_at_from']"
                  class="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600"
                />
              </div>

              <div>
                <span class="text-bold text-opacity-pale">Created At (To)</span>
                <input
                  type="date"
                  class="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600"
                  v-model="moreParams['created_at_to']"
                  name="created_at_to"
                />
              </div>
            </div>
          </form>
        </div>
      </transition>
    </div>

    <div class="mt-8">
      <div class="mt-4">
        <div class="p-6 bg-white rounded-md shadow-md" v-if="chartData1">
          <MultiLineChart :chart-data="chartData1" />
        </div>
      </div>
    </div>

    <div class="mt-4">
      <div class="p-6 bg-white rounded-md shadow-md">
        <div>
          <h6>ESTIMATED MONTHLY MRR</h6>

          <table class="center">
            <thead>
              <tr>
                <th>Service</th>
                <th>Accounts</th>
                <th>Cost per account</th>
                <th>Total Cost</th>
                <th>Subscriptions</th>
                <th>Potential Return</th>
                <th>Revenue</th>
                <th>Margin</th>
                <th>% Margin</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="service of apiData.services || []" :key="service.id">
                <td>
                  {{ service.name }}
                </td>
                <td>
                  {{ service.$extras.groups_count }}
                </td>
                <td>
                  {{ currency  ? rates[currency].symbol : (service.currency && service.currency.symbol)}}{{
                    parseFloat(
                      currency ? (service.meta && service.meta.price
                        ? service.meta.price
                        : 0)/rates[currency].rate : service.meta && service.meta.price
                        ? service.meta.price
                        : 0
                    ).toFixed(2) | numberWithCommas
                  }}
                </td>
                <td>
                  {{ currency  ? rates[currency].symbol : (service.currency && service.currency.symbol)}}{{
                    parseFloat(
                      currency ? (service.meta && service.meta.price
                        ? service.meta.price * service.$extras.groups_count
                        : 0)/rates[currency].rate : service.meta && service.meta.price
                        ? service.meta.price * service.$extras.groups_count
                        : 0
                    ).toFixed(2) | numberWithCommas
                  }}
                </td>
                <td>
                  {{
                    service.$extras.groups_count *
                    optional(service, "group.limit", 0)
                  }}
                </td>
                <td>
                  {{ currency  ? rates[currency].symbol : (service.currency && service.currency.symbol)}}{{
                    parseFloat(
                      currency ? (service.price_shared *
                        optional(service, "group.limit", 0) *
                        service.$extras.groups_count) / rates[currency].rate : service.price_shared *
                        optional(service, "group.limit", 0) *
                        service.$extras.groups_count
                    ).toFixed(2) | numberWithCommas
                  }}
                </td>
                <td>
                  {{ currency  ? rates[currency].symbol : (service.currency && service.currency.symbol)}}{{
                    parseFloat(
                      currency ? ((service.price_shared *
                        optional(service, "group.limit", 0) -
                        service.meta.price) *
                        service.$extras.groups_count || 0)/rates[currency].rate : (service.price_shared *
                        optional(service, "group.limit", 0) -
                        service.meta.price) *
                        service.$extras.groups_count || 0
                    ).toFixed(2) | numberWithCommas
                  }}
                </td>
                <td>
                  {{ currency ? rates[currency].symbol : (service.currency && service.currency.symbol)}}{{
                    parseFloat(
                      currency ? ((deductFee(service.price_shared) *
                        optional(service, "group.limit", 0) -
                        service.meta.price) *
                        service.$extras.groups_count || 0) / rates[currency].rate : (deductFee(service.price_shared) *
                        optional(service, "group.limit", 0) -
                        service.meta.price) *
                        service.$extras.groups_count || 0
                    ).toFixed(2) | numberWithCommas
                  }}
                </td>
                <td>
                  {{
                    parseFloat(
                      (((deductFee(service.price_shared) *
                        optional(service, "group.limit", 0) -
                        service.meta.price) *
                        service.$extras.groups_count || 0) /
                        (service.price_shared *
                          optional(service, "group.limit", 0) *
                          service.$extras.groups_count)) *
                        100 || 0
                    ).toFixed(2) | numberWithCommas
                  }}%
                </td>
              </tr>
              <tr>
                <td><b>Total</b></td>
                <td>{{ total.account | numberWithCommas }}</td>
                <td>
                  {{currency ? rates[currency].symbol : (apiData.services && apiData.services[0] && apiData.services[0].currency.symbol)
                  }}{{
                    parseFloat(currency ? total.cost_per_account / rates[currency].rate : total.cost_per_account).toFixed(2)
                      | numberWithCommas
                  }}
                </td>
                <td>
                  {{currency ? rates[currency].symbol : (apiData.services && apiData.services[0] && apiData.services[0].currency.symbol)
                  }}{{
                    parseFloat(currency ? total.cost/ rates[currency].rate : total.cost).toFixed(2)
                      | numberWithCommas
                  }}
                </td>
                <td>{{ total.subscriptions | numberWithCommas }}</td>
            
                <td>
                  {{currency ? rates[currency].symbol : (apiData.services && apiData.services[0] && apiData.services[0].currency.symbol)
                  }}{{
                    parseFloat(currency ? total.potential_return / rates[currency].rate : total.potential_return).toFixed(2)
                      | numberWithCommas
                  }}
                </td>
                <td>
                  {{ currency ? rates[currency].symbol : (apiData.services && apiData.services[0] && apiData.services[0].currency.symbol)
                  }}{{
                    parseFloat(currency ? total.revenue/rates[currency].rate : total.revenue).toFixed(2) | numberWithCommas
                  }}
                </td>
                <td>
                  {{ currency ? rates[currency].symbol : (apiData.services && apiData.services[0] && apiData.services[0].currency.symbol)
                  }}{{ parseFloat(currency ? total.margin / rates[currency].rate : total.margin).toFixed(2) | numberWithCommas }}
                </td>
                <td>{{ parseFloat(total.margin_percent).toFixed(2) }}%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
      
<script>
import { optional } from "@/helpers/global";
import { toLocal, getDateRangeOfWeek } from "@/helpers/date";
import MultiLineChart from "@/components/Chart/MultiLine.vue";
import moment from "moment";

export default {
  title: "Summary | Reports",
  components: {
    MultiLineChart,
  },
  data() {
    return {
      loaded: false,
      loading: false,
      chartData1: null,
      currency: null,
      type: "count",
      apiData: {
        services: [],
        users: [],
        subscriptions: [],
    },
      moreParams: {
        group_by: "WEEK(created_at)",
        year: null,
        month: null,
        created_at_from: null,
        created_at_to: null,
      },
      total: {
        account: 0,
        cost: 0,
        cost_per_account: 0,
        subscriptions: 0,
        potential_return: 0,
        revenue: 0,
        margin: 0,
        margin_percent: 0
      },
    };
  },
  methods: {
    toLocal,
    optional,
    deductFee(amount) {
      let fee = (1.5 / 100) * amount + 100;
      if (amount < 2000) {
        fee -= 100;
      }

      return fee > 2000 ? amount - 2000 : amount - fee;
    },
    formatLabel(datum, groupBy) {
      if (groupBy == "MONTH(created_at)") {
        return this.months[datum[groupBy]];
      } else if (groupBy == "YEAR(created_at)") {
        return datum[groupBy];
      } else if (groupBy == "WEEK(created_at)") {
        return getDateRangeOfWeek(datum[groupBy], this.moreParams.year);
      } else {
        return new Date(datum[groupBy]).toLocaleDateString();
      }
    },
    setChartData(data) {
      let subData = this.setSubscriptionChartData(data.subscriptions);
      let userData = this.setUsersChartData(data.users);
      this.getTotal(data.services);

      this.chartData1 = {
        labels: userData.labels,
        datasets: [subData[this.type].datasets, userData.datasets],
      };
    },
    setSubscriptionChartData(data) {
      let labels1 = [];
      let labels2 = [];
      let datasets1 = {
        label: `Subscription count`,
        backgroundColor: "blue",
        data: [],
        yAxisID: "y",
      };
      let datasets2 = {
        label: `Subscription amount (${
          this.currency ? `(${this.rates[this.currency].symbol})` : "₦"
        })`,
        backgroundColor: "green",
        data: [],
        yAxisID: "y",
      };

      let count = 0;
      let amount = 0;

      for (let datum of data) {
        count = datum["totalCount"];
        amount = this.currency
          ? datum["totalAmount"] / this.rates[this.currency].rate
          : datum["totalAmount"];
        labels1.push(`${this.formatLabel(datum, this.moreParams.group_by)}`);
        labels2.push(`${this.formatLabel(datum, this.moreParams.group_by)}`);

        datasets1.data.push(count);
        datasets2.data.push(amount);
      }
      return {
        count: {
          labels: labels1,
          datasets: datasets1,
        },
        amount: {
          labels: labels2,
          datasets: datasets2,
        },
      };
    },
    setUsersChartData(data) {
      let labels = [];
      let datasets1 = {
        label: "User Count",
        backgroundColor: "red",
        data: [],
        yAxisID: "y1",
      };

      let count = 0;

      for (let datum of data) {
        count = datum["totalCount"];
        labels.push(`${this.formatLabel(datum, this.moreParams.group_by)}`);
        datasets1.data.push(count);
      }
      return {
        labels,
        datasets: datasets1,
      };
    },
    getTotal(services) {
      this.total = {
        account: 0,
        cost: 0,
        cost_per_account: 0,
        subscriptions: 0,
        potential_return: 0,
        revenue: 0,
        margin: 0,
        margin_percent: 0
      }
      for (let service of services) {
        this.total.account += service.$extras.groups_count
        this.total.cost_per_account += Number(service.meta.price || 0)
        this.total.cost += ((service.meta.price || 0) * service.$extras.groups_count)
        this.total.subscriptions +=
          service.$extras.groups_count * optional(service, "group.limit", 0);
        this.total.potential_return +=
          service.price_shared *
          optional(service, "group.limit", 0) *
          service.$extras.groups_count;
        this.total.revenue +=
          (service.price_shared * optional(service, "group.limit", 0) -
            service.meta.price) *
            service.$extras.groups_count || 0;
        this.total.margin +=
          (this.deductFee(service.price_shared) *
            optional(service, "group.limit", 0) -
            service.meta.price) *
            service.$extras.groups_count || 0;
      }
    this.total.margin_percent = (this.total.margin / this.total.potential_return) * 100
  },
},
  computed: {
    rates() {
      return this.$store.getters["global/rates"];
    },
    months() {
      return this.$store.getters["global/months"];
    },
  },
  watch: {
    moreParams: {
      handler(newValue, oldValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        this.loaded = false;
        this.chartData1 = null;
        Promise.all([
          this.$store.dispatch("subscriptions/getReport", newValue),
          this.$store.dispatch("users/getReport", newValue),
        ]).then(([subscriptions, { data: users, services }]) => {
          this.apiData = {
            subscriptions,
            users,
            services,
          };
          this.getTotal(services);
          this.setChartData(this.apiData);
          this.loaded = true;
        });
      },
      deep: true,
    },
  },
  created() {
    let launchDate = moment(this.$store.getters["global/launchDate"]).startOf(
      "day"
    );
    let threeMonthsAgo = moment().subtract(3, "months").startOf("day");
    this.moreParams.created_at_from =
      launchDate.unix() > threeMonthsAgo.unix()
        ? launchDate.format("YYYY-MM-DD")
        : threeMonthsAgo.format("YYYY-MM-DD");
    this.moreParams.created_at_to = moment().format("YYYY-MM-DD");
    this.moreParams.year = new Date().getFullYear();
  },
};
</script>
<style lang="scss" scoped>
.center {
  margin-left: auto;
  margin-right: auto;
}
table,
th,
td {
  border: 1px solid;
}
</style>