export default {
  SET_SERVICE(state, array) {
    state.service = array;
  },
  SET_META(state, data) {
    state.meta = data;
  },
  SET_SERVICES(state, obj) {
    state.services = obj;
  },
  SET_REPORT(state, obj) {
    state.report = obj;
  },
}
